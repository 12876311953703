import React, {FC} from 'react';

interface Props {
  children: any;
}

const FilterSelectedList: FC<Props> = ({children}) => {
  return (

    <div className="report-filters-selected-list-wrap">
      <div className="report-filters-selected-list">
        {children}
      </div>
    </div>
  );
}

export default FilterSelectedList;