import React, {FC} from 'react';
import {ReactSVG} from "react-svg";
import arrow_svg from "../../assets/images/ArrowLeft.svg";
import check_svg from "../../assets/images/check.svg";
import {useTranslation} from "react-i18next";
import {service} from "../../modules/api";
import {EService} from "../../modules/rest";

interface Props {
  step: number;
  onPrev: (e: any) => void;
  onNext: (e: any) => void;
  prevDisabled?: boolean;
  nextDisabled?: boolean;
  last?: boolean;
}

const ComposePerformerTabs: FC<Props> = ({step, onPrev, onNext, prevDisabled, nextDisabled, last}) => {
  const {t} = useTranslation();
  return (
    <>
      <div className='performer-compose-steps'>
        {service === EService.Arpeggy
          ?
          <>
            <div className='text-24'>{t('STEP')} {step + 1}/3</div>
            <button type='button' className='btn btn-md btn-primary btn-component ms-3' disabled={prevDisabled}
                    onClick={onPrev}>
              <div className='btn-content'>
                {t('PREV')}
              </div>
            </button>
            <button type='button' className='btn btn-md btn-primary btn-component ms-3' disabled={nextDisabled}
                    onClick={onNext}>
              <div className='btn-content'>
                {t('NEXT')}
              </div>
            </button>
          </>
          :
          <>
            <button type='button' className='btn btn-blue p-0' disabled={prevDisabled} onClick={onPrev}>
              <ReactSVG src={arrow_svg} className='react-icon'/>
            </button>
            <div className='text-24 text-dark'>{t('STEP')} {step + 1}/3</div>
            <button type='button' className='btn btn-blue p-0' disabled={nextDisabled} onClick={onNext}>
              <ReactSVG src={last ? check_svg : arrow_svg} className='react-icon'
                        style={last ? {} : {transform: 'rotate(180deg)'}}/>
            </button>
          </>
        }
      </div>
      <div className='performer-compose-tabs'>
        {Array(3).fill({}).map((item, i) => (
          <div className={`performer-compose-tab-item${i <= step ? ' active' : ''}`} key={i}/>
        ))}
      </div>
    </>
  );
}

export default ComposePerformerTabs;