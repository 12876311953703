import React, {FC} from 'react';
import AuthorStatusIcon from '../tracks/AuthorStatusIcon';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {EReviewStatus, IPerformer} from '../../modules/rest';
import {useTranslation} from 'react-i18next';

interface Props {
  allText?: string;
  authors?: IPerformer[];
  activeId?: number|null;
  onClick?: (id: number|null) => void;
  className?: string;
}

const ProfileAuthorsList: FC<Props> = ({authors, allText, onClick, activeId, className}) => {
  const navigate = useNavigate();
  const params = useParams<{ performerId: string }>();
  const {t} = useTranslation();

  if (!authors || authors?.length <= 1) return null;
  return (
    <div className={`performers-header-list ${className || ''}`}>
      {authors.map((a) => (
        <Link
          to={`/performers/${a.id}/albums`}
          key={a.id}
          className={`page__group__item${a.id === Number(activeId || params?.performerId) ? ' active' : ''} ${a.status}`}
          // onClick={handleClick(a.id)}
        >
          <span className='text-truncate'>{a.stageName}</span>
          <AuthorStatusIcon status={a.status} className='page__group__status__item-icon'/>
        </Link>
      ))}
    </div>
  );
}

export default ProfileAuthorsList;