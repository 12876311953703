import React, {FC, useState} from 'react';
import {InputFile} from "../../components/FormControls";
import {IAsset, TIdentifier} from "../../modules/rest";
import {thumb} from "../../modules/utils";
import OutsideClick from "../../components/OutsideClick";

interface Props {
  onChange: (photo: IAsset|null) => void;
  onRemove: (id: TIdentifier) => void;
  photos?: IAsset[]
}

const ProfileGallery: FC<Props> = ({photos, onChange, onRemove}) => {
  const [visible, setVisible] = useState(false);
  const hiddenPhotos = photos?.slice(photos?.length > 5 ? 4 : 5) || [];
  const visiblePhotos = photos?.slice(0, hiddenPhotos.length ? 4 : 5) || [];
  return (
    <div className='performer-compose-gallery'>
      <InputFile onChange={onChange} className='performer-compose-gallery-btn'>
        <div></div>
      </InputFile>
      <div className='performer-compose-gallery-list'>
        {photos?.length
          ?
          <>
            {hiddenPhotos.length ?
              (
                <div className={`performer-compose-gallery-list-item union${visible ? ' visible' : ''}`}
                     onClick={(e) => {
                       e.stopPropagation()
                       setVisible(true)
                     }}>
                  <div>+{hiddenPhotos.length}</div>
                  <OutsideClick
                    outsideClickEvent={() => setVisible(false)}
                    className={`performer-compose-gallery-union-list`}
                    style={{width: hiddenPhotos.length * 45 + 20}}
                  >
                      {hiddenPhotos.map(photo => (
                        <div className="performer-compose-gallery-list-item" key={photo.id}>
                          <img src={thumb(photo.id, 140)} alt={'photo'}/>
                          <button className='btn btn-blue performer-compose-gallery-list-item-remove'
                                  onClick={() => onRemove(photo.id)}/>
                        </div>
                      ))}
                  </OutsideClick>
                </div>
              )
              :
              null
            }

            {visiblePhotos.map(photo => (
              <div className="performer-compose-gallery-list-item" key={photo.id}>
                <img src={thumb(photo.id, 140)} alt={'photo'}/>
                <button className='btn btn-blue performer-compose-gallery-list-item-remove'
                        onClick={() => onRemove(photo.id)}/>
              </div>
            ))}
          </>
          :
          <div></div>
        }

      </div>
    </div>
  );
}

export default ProfileGallery;