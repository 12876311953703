import React, {useLayoutEffect} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import {observer} from 'mobx-react';

import session from './modules/session';
import Loader from './components/Loader';
import {Login, Password} from './routes/auth';
import Header from './components/Header';
import {service} from "./modules/api";
import favicon_zoundo from "./assets/images/favicon_zoundo.png";
import favicon_axtune from "./assets/images/favicon_axtune.png";
import favicon_echotune from "./assets/images/favicon_echotune.svg";
import favicon_apreggy from "./assets/images/favicon_apreggy.ico";
import PerformerPage from "./routes/PerformerPage";
import DocumentsPage from "./routes/documents";
import PasswordReset from "./routes/auth/PasswordReset";
import PerformerCompose from "./routes/performerCompose";
import AlbumsPage from "./routes/albums/AlbumsPage";
import {EService} from "./modules/rest";
import apreggy_theme from './assets/theme/arpeggy/apreggy.json';
import WarningLineWithBtn from "./components/WarningLineWithBtn";
import ReportPage from "./routes/reports/ReportPage";


const logos: Partial<{ [LOGO in EService]: any }> = {
  [EService.Zoundo]: favicon_zoundo,
  [EService.Axtune]: favicon_axtune,
  [EService.Echotune]: favicon_echotune,
  [EService.Arpeggy]: favicon_apreggy,
}

const docTitle: Partial<{ [TITLE in EService]: any }> = {
  [EService.Zoundo]: 'ZOUNDO',
  [EService.Axtune]: 'AXTUNE',
  [EService.Echotune]: 'ECHOTUNE',
  [EService.Arpeggy]: 'Arpeggy',
}

const theme: Partial<{ [THEME in EService]: any }> = {
  [EService.Arpeggy]: apreggy_theme,
}


const App = observer(() => {
  useLayoutEffect(() => {
    if (theme[service]) {
      const r: any = document.querySelector(':root');
      Object.entries(theme[service]).forEach(([key, value]) => {
        r.style.setProperty(key, value);
      })
    }
    document.querySelector('body')?.classList.add(`service-${service}`);
    document.title = docTitle[service];
    const favicon: any = document.getElementById("favicon"); // Accessing favicon element
    favicon.href = logos[service] || favicon_axtune;
  }, []);

  if (!session.ready)
    return (
      <div className='app p-5 d-flex justify-content-center'>
        <Loader text='SESSION_RESTORE'/>
      </div>
    );
  if (!session.user) return (
    <Routes>
      <Route path='/login' element={<Login/>}/>
      <Route path='/password' element={<Password/>}/>
      <Route path='/password/:token' element={<PasswordReset/>}/>
      <Route path='*' element={<Navigate to='/login' replace/>}/>
    </Routes>
  )
  return (
    <div className={`app`}>
      <Header/>
      <div className='content container-fluid' id='content'>
        <div className='content-padding'>
          <Routes>
            {/*<Route path="/performers/*" element={<PerformerPage />}/>*/}
            <Route path="/performers" element={<PerformerPage/>}/>
            <Route path="/performers/:performerId/albums/*" element={<AlbumsPage/>}/>

            <Route path="/performer/:id" element={<PerformerCompose/>}/>
            <Route path="/documents" element={<DocumentsPage/>}/>
            <Route path="/reports" element={<ReportPage />}/>
            {/*<Route path="/performers/:performerId/albums" element={<AlbumsPage />}/>*/}
            <Route path="*" element={<Navigate to="/performers" replace/>}/>
          </Routes>
        </div>
      </div>
    </div>
  );
});

export default App;
