import React, {FC, useCallback, useState} from 'react';
import asyncModal from "react-async-modal";
import {useTranslation} from "react-i18next";
import CloseBtn from "../../components/CloseBtn";
import Loadable from "../../components/Loadable";
import {EFieldGroup, EReviewStatus, IAlbum} from "../../modules/rest";
import {API} from "../../modules/api";
import Empty from "../../components/Empty";
import {Checkbox} from "../../components/FormControls";
import {thumb} from "../../modules/utils";
import Button from "../../components/Button";
import {toast} from "react-toastify";
import {ReactSVG} from "react-svg";
import cover_bg from "../../assets/images/cover_bg.svg";

interface Props {
  resolve(): void;

  data: IAlbum[];
  performerId: number;
}

const SendToReviewAlbumModal: FC<Props> = ({resolve, data, performerId}) => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [albums, setAlbums] = useState<IAlbum[]>(data);
  const [selectedAlbums, setSelectedAlbums] = useState<number[]>([]);

  const handleChange = useCallback((id: number) => () => {
    setSelectedAlbums(prevState => {
      if (prevState.includes(id)) return prevState.filter(it => it !== id);
      return [...prevState, id];
    })
  }, []);

  const getAlbums = async () => {
    try {
      const res = await API.Albums.getAlbums({
        page: 1,
        limit: 100,
        status: EReviewStatus.Draft,
        performerId,
      }, [EFieldGroup.AlbumPerformer]);
      setAlbums(res.data);
    } catch (e) {
      throw e;
    }
  }

  const handleSubmit = useCallback(async () => {
    setLoading(true)
    try {
      for (const id of selectedAlbums) {
        await API.Albums.reviewAlbum(id);
      }
      await getAlbums()
      toast.success(t('ALBUMS_SENT_REVIEW'));
      setSelectedAlbums([]);
    } catch (e: any) {
      toast.error(e);
    } finally {
      setLoading(false)
    }
  }, [selectedAlbums]);

  return (
    <div>
      <h2 className='page-title mb-4 pb-2 text-center text-gradient'>{t('ALBUM_MODER')}</h2>
      <CloseBtn onClick={resolve} className='modal-close'/>
      <div className='text-muted-14 text-medium mb-2 text-center'>{t('ALBUM_MODER_TEXT')}</div>
      <Loadable loading={loading}>
        {albums?.length
          ?
          albums.map(album => (
            <div
              key={album.id}
              className={`moder-album-item${selectedAlbums.includes(album.id) ? ' active' : ''}`}
              onClick={handleChange(album.id)}
            >
              <div className='moder-album-item-content'>
                <Checkbox checked={selectedAlbums.includes(album.id)}/>
                {album.cover
                  ?
                  <img src={thumb(album.cover?.id!, 112)} alt="cover"/>
                  :
                  <ReactSVG src={cover_bg} className='react-icon cover-stub'/>
                }
                <div className='ps-3'>
                  <div className='bold'>{album.title}</div>
                  <div className='mt-1 text-muted-14 text-medium'>{album.performer?.stageName}</div>
                </div>
              </div>
            </div>
          ))
          :
          <Empty show/>}
        <div className="modal-footer-btns">

          <Button
            text="CANCEL"
            color="secondary"
            className="me-3"
            onClick={resolve}
          />
          <Button
            loading={loading}
            onClick={handleSubmit}
            text="SUBMIT_FOR_REVIEW"
            type="button"
            disabled={!selectedAlbums?.length}
          />
        </div>
      </Loadable>
    </div>
  );
}

const openSendToReviewAlbumModal = (props: { data: IAlbum[], performerId?: string }): Promise<void> => {
  return asyncModal(SendToReviewAlbumModal, props, {styles: {modal: {width: 800}}, showCloseIcon: false});
};

export {openSendToReviewAlbumModal};
