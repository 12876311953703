import React, {FC, useCallback} from 'react';
import {useTranslation} from "react-i18next";
import Loadable from "../../components/Loadable";
import {ReactSVG} from "react-svg";
import arrow_svg from "../../assets/images/ArrowLeft.svg";
import button from "../../components/Button";
import {observer, useLocalObservable} from 'mobx-react';
import {
  IAsset,
  IPerformer,
  IUpdatePerformerRequest
} from "../../modules/rest";
import {runInAction} from "mobx";
import {API} from "../../modules/api";
import {toast} from "react-toastify";
import moment from "moment/moment";
import {Input} from "../../components/FormControls";
import InputFile from "../../components/FormControls/InputFile";
import man_passport from './assets/man_passport.png';
import WarningLineWithBtn from "../../components/WarningLineWithBtn";
import stars_svg from '../../assets/images/stars.svg';
import ComposePerformerTabs from "./ComposePerformerTabs";

interface State {
  loading: boolean;
  passportScanError: boolean;
  passportProofError: boolean;
  addressScanError: boolean;
  request: IUpdatePerformerRequest;
  passportScan: IAsset|null;
  passportProof: IAsset|null;
  addressScan: IAsset|null;
}

interface Props {
  onStepChange: (dir: 1|-1) => void;
  performer?: Partial<IPerformer>;

  // getPerformer: () => void;
}

const ComposePerformerStep2: FC<Props> = observer(({onStepChange, performer}) => {
  const {t} = useTranslation();

  const st = useLocalObservable<State>(() => ({
    loading: false,
    passportScan: performer?.passportScan ?? null,
    passportProof: performer?.passportProof ?? null,
    addressScan: performer?.addressScan ?? null,
    passportScanError: false,
    passportProofError: false,
    addressScanError: false,
    request: {
      passport: {
        type: performer?.passport?.type ?? '',
        series: performer?.passport?.series ?? '',
        number: performer?.passport?.number ?? '',
        issuedBy: performer?.passport?.issuedBy ?? '',
        issuedAt: performer?.passport?.issuedAt ? moment(performer?.passport?.issuedAt).format('YYYY-MM-DD') : '',
      },
      address: performer?.address ?? '',
      passportScanId: performer?.passportScan?.id,
      passportProofId: performer?.passportProof?.id,
      addressScanId: performer?.addressScan?.id,
    },
  }));

  const handleSubmit = useCallback(async (e: any) => {
    e?.preventDefault();
    if (!st.request.passportProofId) return runInAction(() => (st.passportProofError = true));
    if (!st.request.passportScanId) return runInAction(() => (st.passportScanError = true));
    if (!st.request.addressScanId) return runInAction(() => (st.addressScanError = true));
    runInAction(() => (st.loading = true));
    try {
      await API.Performers.updatePerformer(performer?.id!, st.request);
      // await getPerformer();
      onStepChange(1);
    } catch (e: any) {
      toast.error(e)
    } finally {
      runInAction(() => (st.loading = false));
    }
  }, [onStepChange, st.request, performer?.id]);

  const btnDisabled =
    !st.request.passport?.number ||
    !st.request.passport?.issuedAt ||
    !st.request.passport?.issuedBy ||
    !st.request.passportScanId ||
    !st.request.passportProofId ||
    !st.request.addressScanId ||
    !st.request.address;

  return (
    <>
      <ComposePerformerTabs
        step={1}
        onPrev={() => onStepChange(-1)}
        onNext={handleSubmit}
        nextDisabled={btnDisabled}/>
      <Loadable loading={st.loading} className='performer-compose-form'>
        <h4 className='bold-24 mb-2'>{t('PERFORMER_PASSPORT_TITLE')}</h4>
        <div className='text-muted-14 mb-4 pb-2'>{t('PERFORMER_PASSPORT_TEXT')}</div>
        <form onSubmit={handleSubmit}>
          <div className="row align-items-end">
            <div className="col pe-3">
              <Input
                label='PASSPORT_LABEL'
                placeholder={t('NUMBER') || ''}
                required
                value={st.request.passport!.number!}
                onChange={(e) => runInAction(() => (st.request.passport!.number! = e.target.value))}
              />
            </div>
            <div className="col ps-3">
              <Input
                label='ISSUE_DATE'
                type="date"
                placeholder={t('ISSUE_DATE') || ''}
                required
                value={st.request.passport!.issuedAt!}
                onChange={(e) => runInAction(() => (st.request.passport!.issuedAt! = e.target.value))}
              />
            </div>
          </div>
          <Input
            label='AUTHORITY'
            placeholder={t('ISSUED_BY') || ''}
            required
            value={st.request.passport!.issuedBy!}
            onChange={(e) => runInAction(() => (st.request.passport!.issuedBy! = e.target.value))}
          />

          {/*<label className='text-dark mb-2'>*/}
          {/*  <span>{t('PASSPORT')}</span>*/}
          {/*  <span className='text-danger text-12'>*</span>*/}
          {/*</label>*/}
          {/*<div className='mb-3 input-group'>*/}
          {/*  <Input*/}
          {/*    className={'w-15'}*/}
          {/*    placeholder={t('SERIES') || ''}*/}
          {/*    required*/}
          {/*    value={st.request.passport!.series!}*/}
          {/*    onChange={(e) => runInAction(() => (st.request.passport!.series! = e.target.value))}*/}
          {/*  />*/}
          {/*  <Input*/}
          {/*    className={'w-20'}*/}
          {/*    placeholder={t('NUMBER') || ''}*/}
          {/*    required*/}
          {/*    value={st.request.passport!.number!}*/}
          {/*    onChange={(e) => runInAction(() => (st.request.passport!.number! = e.target.value))}*/}
          {/*  />*/}
          {/*  <Input*/}
          {/*    type="date"*/}
          {/*    className={'w-25'}*/}
          {/*    placeholder={t('ISSUE_DATE') || ''}*/}
          {/*    required*/}
          {/*    value={st.request.passport!.issuedAt!}*/}
          {/*    onChange={(e) => runInAction(() => (st.request.passport!.issuedAt! = e.target.value))}*/}
          {/*  />*/}
          {/*  <Input*/}
          {/*    className={'w-40'}*/}
          {/*    placeholder={t('ISSUED_BY') || ''}*/}
          {/*    required*/}
          {/*    value={st.request.passport!.issuedBy!}*/}
          {/*    onChange={(e) => runInAction(() => (st.request.passport!.issuedBy! = e.target.value))}*/}
          {/*  />*/}
          {/*</div>*/}

          <InputFile
            description={'PASSPORT_SCAN_INFO'}
            placeholder={t('SCREEN') || ''}
            error={st.passportScanError}
            label={'PASSPORT_SCAN'}
            required
            listFiles={st.passportScan}
            onChange={(file) => runInAction(() => {
              st.passportScanError = false;
              st.passportScan = file;
              st.request.passportScanId = file?.id || '';
            })}
            accept=".zip, .pdf, image/jpeg"
            deletable={false}
          />

          <div className='d-flex  '>
            <InputFile
              placeholder={t('SCREEN') || ''}
              description={'PASSPORT_PROOF_INFO'}
              error={st.passportProofError}
              label={'PASSPORT_PROOF'}
              required
              listFiles={st.passportProof}
              onChange={(file) => runInAction(() => {
                st.passportProofError = false;
                st.passportProof = file;
                st.request.passportProofId = file?.id || '';
              })}
              accept=".zip, .pdf, image/jpeg"
              deletable={false}
            />
            <div className='ms-3'>
              <div className='text-dark-14 mb-2 passport_example-text'>{t("EXAMPLE")}</div>
              <div className='passport_example' />
            </div>
          </div>

          <Input
            label={'REG_ADDRESS'}
            placeholder={t('REG_ADDRESS_PLACEHOLDER') || ''}
            required
            value={st.request.address}
            maxLength={255}
            onChange={(e) => runInAction(() => (st.request.address = e.target.value))}
          />
          <label className='ms-2 mb-2'>{t('ADDRESS_SCAN')}</label>
          <WarningLineWithBtn text='ADDRESS_VERIFICATION_TEXT'  visible className='mb-3'/>
          <div className={'text-muted-14 mb-2 pb-1'}>{t('ADDRESS_VERIFICATION_DOCS')}</div>
          <div className='passport-docs-list text-muted-12'>
            {Array(6).fill({}).map((_, i) => (
              <div key={i} className='passport-docs-list-item'>{t(`ADDRESS_VERIFICATION_DOC_${i + 1}`)}</div>
            ))}
          </div>
          <InputFile
            placeholder={t('SCREEN') || ''}
            error={st.addressScanError}
            required
            className={'mb-3'}
            listFiles={st.addressScan}
            onChange={(file) => runInAction(() => {
              st.addressScanError = false;
              st.addressScan = file;
              st.request.addressScanId = file?.id || '';
            })}
            accept=".zip, .pdf, image/jpeg"
            deletable={false}
          />
          <div className="modal-footer-btns">
            <button className='btn btn-lg btn-third flex-1 me-3' type='button' onClick={() => onStepChange(-1)}>
              <div className='w-100 d-flex align-items-center'>
                <ReactSVG src={arrow_svg} className='position-absolute react-icon' style={{left: 0}}/>
                <div className='flex-grow-1'>{t('BACK')}</div>
              </div>
            </button>
            <button className='btn btn-lg btn-primary flex-1 ms-3' type='submit' disabled={btnDisabled}>
              <ReactSVG src={arrow_svg} className='position-absolute react-icon'
                        style={{transform: 'rotate(180deg)', right: 24}}/>
              {t('NEXT')}
            </button>
          </div>
        </form>
      </Loadable>
    </>
  );
});

export default ComposePerformerStep2;