import React, {FC} from 'react';
import {ReactSVG} from "react-svg";
import chevron_svg from "../assets/icons/chevron_down.svg";
import {useTranslation} from "react-i18next";
import ReportsFiltersCount from "./ReportsFiltersCount";

interface Props {
  title: string;
  len?: number;
  accent?: boolean;
  onClick: () => void;
  onClear: () => void;
}

const ReportsFiltersHead: FC<Props> = ({
                                         len,
                                         onClick,
                                         accent,
                                         onClear,
                                         title,
                                       }) => {
  const {t} = useTranslation();

  return (
    <div className='ps-2 d-flex align-items-center cursor-pointer' onClick={onClick}>
      <div className='d-flex flex-1'>
        <div className='bold'>{t(title)}</div>
        <ReportsFiltersCount onClick={onClear} len={len} accent={accent}/>
      </div>
      <ReactSVG src={chevron_svg} className='react-icon report-chevron'/>
    </div>

  );
}

export default ReportsFiltersHead;