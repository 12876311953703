import React, {FC} from 'react';
import {EReviewStatus} from '../../modules/rest';

interface Props {
  status: EReviewStatus;
  className?: string;
  reviewClock?: boolean;
}

const AuthorStatusIcon: FC<Props> = ({status, className, reviewClock}) => {
  return (
    <div className={`status-icon ${status} ${className || ''}${reviewClock ? ' review-clock' : ''}`}/>
  );
}

export default AuthorStatusIcon;