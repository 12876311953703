import React, {FC} from 'react';
import {useTranslation} from "react-i18next";
import {ReactSVG} from "react-svg";
import chevron_svg from './assets/icons/chevron-right.svg';
import video from './assets/icons/video.svg';
import lang from './assets/icons/land.svg';
import month from './assets/icons/month.svg';
import {IGetPerformerStatsRequest} from "../../modules/rest";

const group = [
  {group_by: 'album', title: 'ALBUMS', icon: video},
  {group_by: 'track', title: 'TRACKS', icon: video},
  {group_by: 'retailer', title: 'PLATFORMS', icon: video},
  {group_by: 'country', title: 'COUNTRIES', icon: lang},
  {group_by: 'period', title: 'MONTHS', icon: month},
]


interface Props {
  request: IGetPerformerStatsRequest;
  onGroupChange: (group: IGetPerformerStatsRequest["group"]) => void;
  toggleFilter: () => void;
  filterVisible?: boolean;
}

const ReportsTabs: FC<Props> = ({request, onGroupChange, toggleFilter, filterVisible}) => {
  const {t} = useTranslation();

  const handleGroup = (group: IGetPerformerStatsRequest['group']) => () => {
    if (group === request.group) return;
    onGroupChange(group);
  }

  const len = (request.country?.length || 0) + ((request?.periodBegin || request?.periodEnd) ? 1 : 0);

  return (
    <div className='reports-tabs'>
      <div className='d-flex'>
        {group.map((item, i) => (
          <div
            className={`page__group__item${request.group === item.group_by ? ' active' : ''}`}
            key={item.group_by}
            onClick={handleGroup(item.group_by as IGetPerformerStatsRequest['group'])}
          >
            {/*<ReactSVG src={item.icon} className='react-icon report-chevron pe-2'/>*/}
            <span>{t(item.title)}</span>
          </div>
        ))}
      </div>
      <button type='button' className='btn btn-third pe-4 ps-2' onClick={toggleFilter}>


        <div className="btn-content d-flex align-items-center">
          <div className='ps-1 pe-3'>
            {len
              ?
              <div className='report-filter-count'>{len}</div>
              :
              <ReactSVG
                src={chevron_svg}
                className='react-icon'
                style={filterVisible ? {transform: 'rotate(180deg)'} : undefined}
              />
            }
          </div>
          {t('FILTER')}
        </div>
      </button>
    </div>
  );
}

export default ReportsTabs;