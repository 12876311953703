import React, {FC, InputHTMLAttributes} from 'react';

import {useTranslation} from 'react-i18next';
import {ReactSVG} from 'react-svg';

interface Props extends InputHTMLAttributes<any> {
  label?: string;
  description?: string;
  className?: string;
  errorText?: string;
  error?: boolean;
  icon?: any;
}

const Input: FC<Props> = ({
                            label,
                            description,
                            className,
                            icon,
                            error,
                            errorText,
                            ...props
                          }) => {
  const {t} = useTranslation();
  return (
    <div className={`form-group${error ? ' error' : ''} ${className || ''}`}>
      {label ?
        <label className={`text-dark mb-2`}>
          <span>{t(label)}</span>
          {props.required ? <span className='text-danger text-12'>*</span> : null}
        </label>
        : null
      }
      {description ?
        <div className={`text-muted-14 mb-2 text-pre-wrap ps-2`}>{t(description)}</div>
        : null
      }
      {errorText && error ? <div className='form-group-error'>{t(errorText)}</div> : null}
      <div className='form-control-wrap'>
        <input
          className={`form-control${icon ? ' form-control-icon' : ''}`}
          {...props} name={(props?.value || '') + ''}
          autoComplete='new-input'
        />
        <div className='form-control-border'/>
        <div className='form-control-gradient'/>
        {icon ? <div className='input-icon'><ReactSVG src={icon}/></div> : null}
        {props.placeholder && (props.type === 'date' || props.type === 'time') && !props.value
          ?
          <div className="form-control-placeholder">{props.placeholder}</div>
          :
          null
        }
      </div>
    </div>
  );
};

export default Input;