import React, {FC, useCallback, useEffect, useState} from 'react';
import {Route, Routes, useNavigate, useParams} from "react-router-dom";
import Button from "../../components/Button";
import {useTranslation} from "react-i18next";
import ProfileAuthorsList from "../performer/ProfileAuthorsList";
import Author from "../tracks/Author";
import {ReactSVG} from "react-svg";
import music_album_svg from "../../assets/images/music_album.svg";
import {EFieldGroup, EReviewStatus, IAlbum, IPerformer, TIdentifier} from "../../modules/rest";
import {API} from "../../modules/api";
import {toast} from "react-toastify";
import Loader from "../../components/Loader";
import AlbumCompose from "./AlbumCompose";
import AlbumsList from "./albumsList/AlbumsList";
import TrackCompose from "../tracks/compose";
import TracksPage from "../tracks";
import EditAlbum from "./edit";
import Album from "./Album";
import WarningLineWithBtn from "../../components/WarningLineWithBtn";
import {openCreatePerformerModal} from "../../modals";

interface Props {
}

const AlbumPage: FC<Props> = ({}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const params = useParams<{ performerId?: string, '*': string }>();

  const [authors, setAuthors] = useState<IPerformer[]>();
  const [author, setAuthor] = useState<IPerformer>();
  const [album, setAlbum] = useState<IAlbum>();
  const [albums, setAlbums] = useState<IAlbum[]>();

  useEffect(() => {
    if (params.performerId) {
      getPerformersList();
      init();
    } else getPerformersList();
  }, [params.performerId]);

  const init = useCallback(async () => {
    try {
      const performer = await API.Performers.getPerformer(params.performerId!, [EFieldGroup.PerformerStatus]);
      setAuthor(performer);
    } catch (e: any) {
      navigate(`/performers`);
      toast.error(e);
    }
  }, [params.performerId]);

  const getPerformersList = useCallback(() => {
    API.Performers.getList({page: 1, limit: 100}, [EFieldGroup.PerformerStatus])
      .then(performers => {
        setAuthors(performers.data)
        const performerId = params?.performerId || performers.data[0]?.id;
        if (performerId && !params?.performerId) {
          navigate(`/performers/${performerId}/albums`);
        }
      })
  }, [params?.performerId]);

  useEffect(() => {
    const performer = authors?.find(performer => performer.id === author?.id);
    if (performer) setAuthor(performer);
  }, [author?.id, authors]);

  useEffect(() => {
    if (!params['*']?.includes('tracks') && album) {
      setAlbum(undefined);
    }
  }, [params['*']]);


  const getAlbum = useCallback(async (albumId: TIdentifier) => {
    try {
      const album = await API.Albums.getAlbum(albumId, [EFieldGroup.AlbumStatus, EFieldGroup.AlbumPerformer, EFieldGroup.AlbumTracks])
      setAlbum(album);
    } catch (e: any) {
      toast.error(e);
    }
  }, []);

  const handleAddPerformer = useCallback(() => {
    openCreatePerformerModal().then(performer => {
      if (performer) navigate(`/performers/${performer.id}/albums`);
    })
  }, []);

  if (!author) return <Loader/>;

  const hiddenProfile = !albums?.length;
  const approvedAlbums = albums?.filter(item => item.status === EReviewStatus.Approve) || [];

  return (
    <>
      {(authors?.length && authors?.length > 1) || (authors?.length === 1 && author.status === EReviewStatus.Approve)
        ?
        <div className='d-flex justify-content-between mb-4 pb-2'>
          <h1 className="page-title">
            {t('PERFORMERS')}
            {/*<span className='text-muted'> {count || ''}</span>*/}
          </h1>
          <Button text="ADD_AUTHOR" icon={['fas', 'plus']} onClick={handleAddPerformer}  />
        </div>
        :
        null
      }
      <ProfileAuthorsList authors={authors} className='mb-3'/>
      <WarningLineWithBtn
        className='mb-2 pb-1'
        type='warning'
        text={'NO_PROFILE'}
        btnText={'FILL_PROFILE'}
        onClick={() => navigate(`/performer/${author.id}`)}
        btnClassName='btn-blue'
        visible={(author.status === EReviewStatus.Draft || author.status === EReviewStatus.Reject) && !!approvedAlbums?.length}
      />
      <div className={`album-header${params['*']?.includes('tracks') || album ? ' visible' : ''}`}>
        {!hiddenProfile && <Author author={author} editable={!!approvedAlbums?.length}/>}
        <Album album={album} getAlbum={getAlbum} performerId={params.performerId}/>
      </div>
      {!params['*']?.includes('tracks')
        ?
        <div className={`album-list-header${!hiddenProfile ? ' mt-4' : ''}`}>
          <div className='d-flex align-items-center pe-3 '>
            <div className='title-icon'>
              <ReactSVG src={music_album_svg}/>
            </div>
            <div className='overflow-hidden d-grid'>
              <h4 className='bold-24 text-truncate text-muted'><span
                className='text-white'>{t('ALBUMS')}</span>
              </h4>
            </div>
          </div>
          {!params['*']
            ?
            <div className='d-flex'>
              <Button
                text='ADD_ALBUM'
                color={'third'}
                icon={['fas', 'plus']}
                onClick={() => navigate('add/')}
              />
              <Button
                className='ms-3'
                text='ADD_SINGLE'
                icon={['fas', 'plus']}
                onClick={() => navigate('addSingle/')}
              />
            </div>

            : null
          }
        </div>
        :
        null
      }
      <Routes>
        <Route path='/' element={<AlbumsList
          performers={authors}
          performer={author}
          updatePerformers={init}
          setAlbums={setAlbums}/>}/>
        <Route path='/add/' element={<AlbumCompose mode='add' author={author}
                                                   onUpdated={(album) => {
                                                     navigate(`/performers/${author.id}/albums/${album.id}/tracks`)
                                                   }}/>}
        />
        <Route path='/addSingle/' element={<TrackCompose
          isSingle
          title='ADD_SINGLE'
          mode='add'
          author={author}
          onUpdated={() => {
            init();
            navigate(`/performers/${author.id}/albums`)
          }}/>}
        />
        <Route path='/:albumId/edit/' element={<EditAlbum author={author}/>}/>
        <Route path="/:albumId/tracks/*" element={<TracksPage performer={author} getAlbum={(albumId) => {
          init()
          getAlbum(albumId)
        }} album={album}/>}/>
        {/*<Route path='*' element={<Navigate to='/author/tracks/' replace />} />*/}
      </Routes>
    </>
  );
}

export default AlbumPage;