import React, {useCallback, useEffect} from 'react';
import {observer, useLocalObservable} from "mobx-react";
import {ECountry, EFieldGroup, IGetPerformerStatsRequest, IPerformerStats, ITrack,} from "../../modules/rest";
import {runInAction} from "mobx";
import './report.scss'
import {useTranslation} from "react-i18next";
import ReportsFilters from "./ReportsFilters";
import Empty from "../../components/Empty";
import ReportsListItem from "./ReportsListItem";
import {API} from "../../modules/api";
import ReportsTabs from "./ReportsTabs";
import {toast} from "react-toastify";
import ReportsListHead from "./ReportsListHead";
import Loadable from "../../components/Loadable";
import Loader from "../../components/Loader";
import {formatDate} from "../../modules/utils";
import ReportsTotal from "./ReportsTotal";


interface State {
  // pager?: IGetStatsResponse<unknown>;
  // request: IGetStatsRequest;
  request: IGetPerformerStatsRequest;
  pager?: IPerformerStats;
  loading: boolean;
  filterVisible?: boolean;
  ready: boolean;
  tracksFilter: ITrack[];
  info?: { lastReport: string; nextReport: string };
}

// const startPeriod = moment().add('month', -3).format('YYYY-MM');

const ReportPage = observer(() => {
  const {t} = useTranslation();
  const st = useLocalObservable<State>(() => ({
    ready: false,
    loading: false,
    filterVisible: true,
    request: {
      group: 'album',
      sort: 'views',
      country: [] as ECountry[],
      embed: [],
    },
    tracksFilter: [],
  }));

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    try {
      const info = await API.Reports.getInfo();
      runInAction(() => (st.info = info));
      fetch()
    } catch (e) {

    }
  }

  const fetch = (req?: IGetPerformerStatsRequest) => {
    runInAction(() => (st.loading = true));
    API.Stats.getPerformerStats({...st.request, ...req}, [EFieldGroup.TrackFile, EFieldGroup.TrackAlbum, EFieldGroup.TrackSample, EFieldGroup.AlbumPerformer])
      .then((pager) => {
        runInAction(() => {
          st.pager = pager;
        });
      })
      .catch((e) => toast.error(e, {toastId: 'stats_fetch'}))
      .finally(() => runInAction(() => {
        st.loading = false;
        st.ready = true;
        if (req) st.request = {...st.request, ...req};
      }));
  }

  const toggleFiltersVisible = useCallback(() => {
    runInAction(() => {
      st.filterVisible = !st.filterVisible;
    })
  }, []);

  return (
    <section className={`reports-page${st.filterVisible ? ' filter-visible' : ''}`}>
      <h1 className='page-title mb-1'>{t('REPORTS')}</h1>
      <div className='mb-4'>
      {st.info
        ?
        <>
          <div><span className='text-muted'>{t('LAST_REPORT')}</span> <span>{st.info.lastReport}</span></div>
          <div><span className='text-muted'>{t('NEXT_REPORT')} ≈{formatDate(st.info.nextReport)}</span></div>
        </>
        :
        null
      }
      </div>
      {!st.ready
        ?

        <Loader/>
        :
        <>
          <ReportsTabs
            filterVisible={st.filterVisible}
            toggleFilter={toggleFiltersVisible}
            request={st.request}
            onGroupChange={(group) => {
              runInAction(() => {
                fetch({group});
              })
            }}
          />
          <Loadable loading={st.loading} className='d-flex align-items-start'>
            <div className="report-filters-content">
              <ReportsListHead group={st.request.group}/>
              {st.pager?.data?.length ?
                st.pager.data?.map((item, i) => (
                  <ReportsListItem
                    item={item}
                    key={i}
                    index={i}
                    group={st.request.group}
                    // sort={st.request.sort}
                  />
                ))
                :
                <Empty show className='mt-4'/>
              }
              <ReportsTotal pager={st.pager} />
            </div>
            <ReportsFilters
              visible={st.filterVisible}
              request={st.request}
              updateState={(request: Partial<IGetPerformerStatsRequest>) => {
                runInAction(() => {
                  st.request = {...st.request, ...request}
                  fetch();
                })
              }}
            />
          </Loadable>
        </>
      }
    </section>
  );
});

export default ReportPage;
