import React, {FC, ReactNode, useState} from 'react';
import {useTranslation} from "react-i18next";
import {formatNumber, thumb} from "../../modules/utils";
import {
  IAlbum,
  IGetPerformerStatsRequest,
  IPerformerStatsItem, ITrack,
} from "../../modules/rest";
import {ReactSVG} from "react-svg";
import period_svg from './assets/icons/month.svg';
import land_svg from './assets/icons/land.svg';
import geos from "../../translates/geos.json";
import cover_bg from "../../assets/images/cover_bg.svg";
import Track, {TrackTitle} from "../../components/Track";
import Player from "../../components/Player";

interface Props {
  item: IPerformerStatsItem;
  index: number;
  group: IGetPerformerStatsRequest['group'];
}

const ReportsListItem: FC<Props> = ({item, index, group}) => {
  const {t} = useTranslation();
  const [activeTrackId, setActiveTrackId] = useState<number>();

  const renderItem = (): ReactNode => {
    if (group === 'country') return (
      <>
        <div className="report__list__item-icon">
          <ReactSVG src={land_svg} className='react-icon'/>
        </div>
        {/*@ts-ignore*/}
        <div className='bold'>{geos[item.item?.toUpperCase()]?.name}</div>
      </>
    )
    if (group === 'retailer') return <div className='ps-4 bold'>{item?.item as string}</div>

    if (group === 'period') return <>
      <div className="report__list__item-icon">
        <ReactSVG src={period_svg} className='react-icon'/>
      </div>
      <div className='bold'>{item?.item as string}</div>
    </>

    if (group === 'album') {
      const album = item?.item as IAlbum;
      return (
        <>
          {album.cover
            ?
            <img src={thumb(album.cover?.id!, 112)} alt="cover"/>
            :
            <ReactSVG src={cover_bg} className='react-icon cover-stub'/>
          }
          <div className='ps-3'>
            <div className='bold'>{album.title}</div>
            <div className='mt-1 text-muted-14 text-medium'>{album.performer?.stageName}</div>
          </div>
        </>
      )
    }
    if (group === 'track') {
      const track = item?.item as ITrack;
      return (
        <>
          <Player
            track={track}
            active={activeTrackId === track.id}
            onChange={(activeTrackId) => setActiveTrackId(activeTrackId)}
          />
          <TrackTitle
            track={track}
            // active={st.activeTrackId === track.id}
            width={200}
            className={'ps-3'}
          />
        </>
      )
    }

    return null;
  }

  return (
    <div className='d-flex align-items-center mt-3'>
      <div className='text-muted' style={{width: 50, minWidth: 50}}>
        {index + 1}
      </div>
      <div className='card card-active flex-1 reports-list-item'>
        <div className="card-body d-flex w-100">
          <div className="flex-1 d-flex align-items-center">{renderItem()}</div>
          <div className={`reports-list-number-item text-muted`}>{formatNumber(item.views!)}</div>
          {/*<div className={`reports-list-number-item`}>{formatNumber(item.paidViews)}</div>*/}
          <div className={`reports-list-number-item bold`}>${formatNumber(item.profit, 2)}</div>
          {/*<div className={`reports-list-number-item`}>${formatNumber(item.cpm || 0, 2)}</div>*/}
        </div>
      </div>
    </div>
  );
}

export default ReportsListItem;
