import {IPerformer} from '../../modules/rest';
import asyncModal from 'react-async-modal';
import React, {FC, FormEvent, useCallback, useState} from 'react';
import Button from '../../components/Button';
import Loadable from '../../components/Loadable';
import {API} from '../../modules/api';
import {toast} from 'react-toastify';
import {useTranslation} from "react-i18next";
import CloseBtn from "../../components/CloseBtn";
import {Input} from "../../components/FormControls";
import {runInAction} from "mobx";


interface Props {
  resolve(performer?: IPerformer): void;
}

const CreatePerformerModal: FC<Props> = ({resolve}) => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [stageName, setStageName] = useState('');

  const submit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      setLoading(true);
      API.Performers.createPerformer({stageName})
        .then((res) => {
          toast.success(t('SUCCESS_CREATE_PROFILE'));
          resolve(res);
        })
        .catch(toast.error)
        .finally(() => setLoading(false));
    },
    [stageName]
  );

  return (
    <Loadable loading={loading}>
      <form onSubmit={submit}>
        <h2 className="page-title text-center text-gradient mb-4 pb-2">{t('ADD_PERFORMER')}</h2>
        <CloseBtn onClick={resolve} className='modal-close'/>
        <Input
          label={'NICKNAME'}
          description={'NICKNAME_TEXT'}
          className={'mb-3 form-group-labels'}
          placeholder={t('NICKNAME_PLACEHOLDER') || ''}
          required
          value={stageName}
          maxLength={50}
          onChange={(e) => setStageName(e.target.value)}
        />
        <div className="modal-footer-btns">
          <Button text="CANCEL" color="third" className="me-3" onClick={() => resolve()}/>
          <Button
            className={'ms-3'}
            text="CREATE"
            type="submit"
            disabled={!stageName}
          />
        </div>
      </form>
    </Loadable>
  );
};

const openCreatePerformerModal = (): Promise<IPerformer> => {
  return asyncModal(CreatePerformerModal, {}, {styles: {modal: {width: 600}}, showCloseIcon: false});
};

export {openCreatePerformerModal};
