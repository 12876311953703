import {IBatch, IAsset} from '../../modules/rest';
import asyncModal from 'react-async-modal';
import {FC, FormEvent, useCallback, useState} from 'react';
import Button from '../../components/Button';
import {InputFile} from '../../components/FormControls';
import {download} from '../../modules/utils';
import Loadable from '../../components/Loadable';
import {API} from '../../modules/api';
import {toast} from 'react-toastify';
import {useTranslation} from "react-i18next";
import CloseBtn from "../../components/CloseBtn";

export enum SignSide {
  One,
  Two,
}

interface Props {
  contract: IBatch;
  side: SignSide;

  resolve(contract: IBatch|false): void;
}

const ContractModal: FC<Props> = (props) => {
  const {t} = useTranslation();
  const [doc, setDoc] = useState<IAsset|null>(null);
  const [loading, setLoading] = useState(false);

  const submit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      setLoading(true);
      API.Batches.signOneBatch(props.contract.id, {documentId: doc?.id!})
        .then((res) => {
          toast.success(t('CONTRACT_SENT'));
          props.resolve(res);
        })
        .catch(toast.error)
        .finally(() => setLoading(false));
    },
    [setLoading, props, doc, t]
  );

  return (
    <Loadable loading={loading}>
      <form onSubmit={submit}>
        <div className='d-flex align-items-center justify-content-between'>
          <h5 className="mb-4 me-3">{t('TREATY')} №{props.contract?.tag}</h5>
          <CloseBtn onClick={() => props.resolve(false)}/>
        </div>
        <div className="mb-3">
          {t('CONTRACT_STATUS')}:{' '}
          <span className="bold">
            {!props.contract && t('NOT_SIGNED')}
            {(props.contract.contractOne && !props.contract.contractBlank) && t('SIGNED_BY_ONE')}
            {(props.contract.contractTwo && !props.contract.contractBlank) && t('CONTRACT_SIGNED')}
          </span>
        </div>


        <div className="mb-3">
          <div className="mb-2">
            <b>{t('STEP')} 1:</b> {t(!props.contract.contractTwo ? 'CONTRACT_STEP_1_1' : 'CONTRACT_STEP_1_2')}
          </div>
          <div>
            <Button
              icon={['fas', 'download']}
              color={'secondary'}
              className='ps-4'
              size="sm"
              text={'DOWNLOAD_FORM'}
              onClick={() => download(props.contract.contractTwo || props.contract.contractBlank!)}
            />
          </div>
        </div>

        <div className="mb-3">
          <b>{t('STEP')} 2:</b> {t('CONTRACT_STEP_2')}
        </div>

        <div className="mb-3">
          <div className="mb-2">
            <b>{t('STEP')} 3:</b> {t('CONTRACT_STEP_3')}
          </div>
          <InputFile
            value={doc}
            label={'UPLOAD_SCAN'}
            onChange={setDoc}
            acceptText='.pdf, .zip'
            accept=".pdf, .zip"
          />
        </div>

        <div className="modal-footer-btns">
          <Button
            text="SIGN_CONTRACT"
            type="submit"
            disabled={!doc}
            icon={['fas', 'check']}
          />
          <Button text="CANCEL" color="secondary" className="ms-2" outline onClick={() => props.resolve(false)}/>
        </div>
      </form>
    </Loadable>
  );
};

const signContractModal = (contract: IBatch, side: SignSide): Promise<IBatch|false> => {
  return asyncModal(ContractModal, {contract, side}, {styles: {modal: {width: 600}}, showCloseIcon: false});
};

export {signContractModal};
