import React, {FC, useCallback, useEffect, useState} from 'react';
import {API} from "../modules/api";
import {EFieldGroup, IPerformer} from "../modules/rest";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import Loader from "../components/Loader";

interface Props {
}

const PerformerPage: FC<Props> = () => {
  const navigate = useNavigate();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    getPerformersList();
  }, []);

  const getPerformersList = useCallback(() => {
    API.Performers.getList({page: 1, limit: 100}, [EFieldGroup.PerformerStatus])
      .then(performers => {
        const performerId = performers.data[0]?.id;
        if (performerId) {
          navigate(`/performers/${performerId}/albums`);
        }
      }).finally(() => setReady(true));
  }, []);

  if(!ready) return <Loader />
  return (
    <div></div>
  );
}

export default PerformerPage;