import React, {FC, useCallback, useState} from 'react';
import {useTranslation} from "react-i18next";
import Button from "../../../components/Button";
import {useNavigate} from "react-router-dom";
import {ReactSVG} from "react-svg";
import chevron from '../../../assets/images/chevron.svg';

interface Props {
}

const AlbumsListEmpty: FC<Props> = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const [visible, setVisible] = useState(false);
  const toggleVisible = useCallback(() => {
    setVisible(prevState => !prevState);
  }, []);

  const lines = Math.ceil(window.innerHeight / 300);
  return (
    <div className='albums-list-empty'>
      <div className="albums-list-empty-bg">
        {Array(lines).fill({}).map((_, i) => (
          <div className="d-flex mb-4" key={i}>
            {Array(6).fill({}).map((_, i) => <div className="albums-list-empty-bg-item" key={i}/>)}
          </div>
        ))}
      </div>
      <div className="albums-list-empty-content">
        <div className='d-flex justify-content-start'>
            <h2 className='text-24 mb-3'>{t('HOW_IT_WORK')}</h2>
        </div>
        {Array(5).fill({}).map((_, i) => (
          <div className="albums-list-empty-content-item" key={i}>
            <div className='albums-list-empty-content-item-number'>{i + 1}</div>
            <div className='text-pre-wrap'>{t(`ALBUMS_EMPTY_TEXT_${i + 1}`)}</div>
          </div>
        ))}
        <div className='albums-list-empty-tracks ' onClick={toggleVisible}>
          <div>{t('ALBUMS_EMPTY_TEXT_6')}</div>
          <div className="albums-list-empty-tracks-line"/>
          <ReactSVG src={chevron} className='react-icon'
                    style={{transform: `rotate(${visible ? -180 : 0}deg)`, transition: 'all .3s ease'}}/>
        </div>
        {visible &&
          <>
            <div className='d-flex justify-content-start'>
              <h2 className='text-24 mb-2 mt-3'>{t('ALBUMS_EMPTY_TEXT_6')}:</h2>
            </div>
            <p className='text-muted text-pre-wrap mt-2 mb-3'>{t('ALBUMS_EMPTY_TEXT_7')}</p>
          </>
        }
        <div className='d-flex pt-4 albums-list-empty-footer'>
          <Button
            className='me-3 flex-1'
            text='ADD_ALBUM'
            color={'third'}
            size='lg'
            icon={['fas', 'plus']}
            onClick={() => navigate('add/')}
          />
          <Button
            className='ms-3 flex-1'
            text='ADD_SINGLE'
            size='lg'
            icon={['fas', 'plus']}
            onClick={() => navigate('addSingle/')}
          />
        </div>
      </div>
    </div>
  );
}

export default AlbumsListEmpty;