import React, {ChangeEvent, FC, InputHTMLAttributes, useCallback, useState} from 'react';

import {useTranslation} from "react-i18next";
import {ReactSVG} from "react-svg";
import attach_svg from '../../assets/images/attach.svg';
import close_svg from '../../assets/images/close.svg';
import {API} from "../../modules/api";
import {IAsset} from "../../modules/rest";
import {toast} from 'react-toastify';
import {download, thumb} from "../../modules/utils";
import Loadable from "../Loadable";

// @ts-ignore
interface Props extends InputHTMLAttributes<any> {
  label?: string;
  description?: string;
  className?: string;
  fileClassName?: string;
  icon?: any;
  acceptText?: string;
  listFiles?: any;
  onRemoveFile?: (id: number) => void;
  value?: any;
  deletable?: boolean;
  error?: boolean;
  onChange: (file: IAsset|null, remove?: boolean) => void;
  children?: any;
}

const InputFile: FC<Props> = ({
                                label,
                                description,
                                className,
                                fileClassName,
                                icon,
                                listFiles,
                                error,
                                acceptText,
                                onChange,
                                deletable = true,
                                value,
                                children,
                                ...props
                              }) => {
  const {t} = useTranslation();

  const [asset, setAsset] = useState<IAsset|null>();
  const [progress, setProgress] = useState<number>(0);
  const [uploading, setUploading] = useState<boolean>(false);

  const change = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const file: File = e.target.files![0];

      e.target.value = '';

      // if (props.value) onChange(null);

      const fileSize = file.size;
      const chunkSize = 1024 * 512;

      let offset = 0;
      let id: string;

      const chunkReaderBlock = () => {
        const reader = new FileReader();
        const blob = file.slice(offset, chunkSize + offset);
        reader.onload = () => {
          const chunk = (reader.result as string).split(',')[1];
          API.Assets.chunkPartial({
            id,
            chunk,
          })
            .then((res) => {
              setProgress(res.progress);
              offset += chunkSize;
              if (offset < fileSize) {
                chunkReaderBlock();
              } else {
                setUploading(false);
                onChange(res.asset as IAsset);
              }
            })
            .catch((e: any) => {
              setUploading(false);
              toast.error(e);
            });
        };
        reader.readAsDataURL(blob);
      };

      setProgress(0);
      setUploading(true);

      API.Assets.beginPartial({
        fileSize,
        chunkSize,
        fileType: file.type,
        fileName: file.name,
      })
        .then((res) => {
          id = res.id;
          chunkReaderBlock();
        })
        .catch((e) => {
          setUploading(false);
          toast.error(e);
        });
    },
    [onChange, value]
  );

  if (children) return (
    <Loadable loading={uploading}>
      <label className={`form-group-file ${className || ''}`}>
        <input className={`form-control`} {...props} type='file' onChange={change} required={false}/>
        {children}
      </label>
    </Loadable>
  )

  return (
    <div className={`form-group ${className || ''}${error ? ' error' : ''}`}>
      {label ?
        <label className={`text-dark mb-2 text-pre-wrap`}>
          <span>{t(label)}</span>
          {props.required ? <span className='text-danger text-12'>*</span> : null}
        </label>
        : null
      }
      {description ?
        <div className={`text-muted-14 mb-2 text-pre-wrap ps-2`}>{t(description)}</div>
        : null
      }
      <div className={`form-group-file ${fileClassName || ''}`}>
        {icon
          ?
          <div className='form-file-icon'>
            {listFiles
              ?
              <img src={thumb(listFiles.id, 208)} alt="img"/>
              :
              <ReactSVG src={icon}/>
            }
          </div>
          :
          null
        }
        <div className='flex-grow-1'>
          <div className='form-file-label-wrap'>
            <label className='form-file-label'>
              <div className='btn-content'>
                <input className={`form-control`} {...props} type='file' onChange={change} required={false}/>
                <div className='d-flex align-items-center'>
                  <ReactSVG src={attach_svg}/>
                  <span className='ps-2'>{t('ATTACH')}</span>
                </div>
              </div>
            </label>
            {(uploading) && (
              <div className="input-file__progress"
                   style={{backgroundImage: `radial-gradient(closest-side, #201F24 60%, transparent 80% 100%),conic-gradient(#CC4DDF ${progress}%, rgba(255, 255, 255, 0.1) 0)`}}/>
            )}
            {acceptText ? <div className='text-14 ms-2'>{acceptText}</div> : null}
          </div>
          <div className='d-flex flex-wrap mt-2'>
            {listFiles?.length || listFiles || value
              ?
              (Array.isArray(listFiles) ? listFiles : [listFiles || value]).map((file, i) => (
                <div key={i} className='form-file-list-item'>
                  <div className='text-truncate' onClick={() => download(file)}>{file.name}</div>
                  {deletable &&
                    <div className='ms-2 cur-pointer' onClick={() => onChange(null)}><ReactSVG src={close_svg}/>
                    </div>}
                </div>
              ))
              :
              props.placeholder ?
                <div className='form-group-file-placeholder mb-1 ms-2 ps-1'>{props.placeholder}</div> : null
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default InputFile;