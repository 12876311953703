import React, {FC, useCallback, useState} from 'react';
import {EReviewStatus, IAlbum, IPerformer} from "../../../modules/rest";
import {useTranslation} from "react-i18next";
import AuthorStatusIcon from "../../tracks/AuthorStatusIcon";
import {useNavigate} from "react-router-dom";
import {download, thumb} from "../../../modules/utils";
import {Icon} from "../../../components/fontawesome";
import OutsideClick from "../../../components/OutsideClick";
import cover_bg from "../../../assets/images/cover_bg.svg";
import {ReactSVG} from "react-svg";
import {ReviewStates} from "../../../modules/directory";
import MusicPlatforms from "../../../components/MusicPlatforms";

interface Props {
  album: IAlbum;
  performer: IPerformer;
  deleteAlbum: (e: any) => void;
  draftAlbum: (e: any) => void;
  reviewAlbum: (e: any) => void;
  signContract: (e: any) => void;
  editAlbum: () => void;
}

const AlbumsListItem: FC<Props> = ({
                                     album,
                                     performer,
                                     deleteAlbum,
                                     draftAlbum,
                                     editAlbum,
                                     reviewAlbum,
                                     signContract,
                                   }) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [visibleControls, setVisibleControls] = useState(false);

  const handleClick = useCallback(() => {
    navigate(`${album.id}/tracks`);
  }, [album.id, navigate]);

  const handleControlsClick = useCallback((e: any) => {
    e.stopPropagation();
    setVisibleControls(prevState => !prevState);
  }, []);

  const handleFillProfileClick = useCallback((e: any) => {
    e.stopPropagation();
    navigate(`/performer/${performer.id}`);
  }, [navigate, performer.id]);

  const closeControls = useCallback(() => {
    setVisibleControls(false);
  }, []);

  const pendingStatus = (!album?.batch?.contractTwo && album?.batch?.contractOne) || (!album?.batch && album.status === EReviewStatus.Approve);
  const fillProfileStatus = (album.status === EReviewStatus.Approve && (performer?.status === EReviewStatus.Draft || performer?.status === EReviewStatus.Reject));

  return (
    <OutsideClick outsideClickEvent={closeControls}>
      <div
        className={`albums-list-item ${(pendingStatus ? 'pending' : album.status)}${fillProfileStatus ? ' fill-profile' : ''}`}
        onClick={handleClick}
        onMouseLeave={closeControls}>
        <div className="albums-list-item-status-text">
          {pendingStatus && t('CONTRACT_WAIT')}
          {/*{album.status === EReviewStatus.Review && t('UNDER_REVIEW')}*/}
          {album.status === EReviewStatus.Reject && <>
            <div className={'text-danger'}>{t('REJECTED')}</div>
            <div className='text-14 mt-1'>{album.rejectReason}</div>
          </>
          }
        </div>
        <div className="albums-list-item-content">
          <div className="albums-list-item-status">
            <AuthorStatusIcon
              reviewClock
              status={album.status!}
              className='albums-list-item-status-content'
            />
            <div className='albums-list-item-status-name'>{t(ReviewStates[album.status!])}</div>
          </div>
          <div className="albums-list-item-cover">
            {album.cover
              ?
              <img src={thumb(album.cover?.id!, 406)} alt="cover"/>
              :
              <ReactSVG src={cover_bg} className='react-icon'/>
            }
          </div>
          <div className={'d-flex px-2 align-items-center'} style={{zIndex: 1}}>
            <div className='d-grid  flex-grow-1'>
              <h4 className='albums-list-item-title text-truncate'>{album.title}</h4>
              <div className='d-flex justify-content-start align-items-center  mt-1 mb-2'>
                <p className='text-muted-14 mb-0'>
                  {album.isSingle
                    ? t('SINGLE')
                    : `${album.tracksCount || 0} ${t('tracks')}`
                  }
                </p>
                <MusicPlatforms links={album.links} title={album.title}/>
              </div>
            </div>
            {album.status !== EReviewStatus.Approve
              ?
              <div className="albums-list-item-controls" onClick={handleControlsClick}>
                <Icon icon={['fas', 'ellipsis']}/>
                {visibleControls
                  ?
                  <div className='albums-list-item-controls-hidden'>
                    <div className='albums-list-item-controls-hidden-item'
                         onClick={album.status === EReviewStatus.Draft ? editAlbum : draftAlbum}>
                      <span
                        className='text-medium ps-3 text-nowrap'>{t(album.isSingle ? 'EDIT_SINGLE' : 'EDIT_ALBUM')}</span>
                      <div className="albums-list-item-controls-icon">
                        <div className='icon-edit' />
                      </div>
                    </div>
                    <div className='albums-list-item-controls-hidden-item text-danger' onClick={deleteAlbum}>
                      <span
                        className='text-medium ps-3 text-nowrap'>{t(album.isSingle ? 'DELETE_SINGLE' : 'DELETE_ALBUM')}</span>
                      <div className="albums-list-item-controls-icon">
                        <Icon icon={['fas', 'trash-alt']}/>
                      </div>
                    </div>
                  </div>
                  :
                  null
                }
              </div>
              :
              null
            }
          </div>
          <div className='albums-list-item-btn'>
            {fillProfileStatus &&
              <button type='button' className={'btn btn-sm btn-blue'}
                      onClick={handleFillProfileClick}>
                <div>{t('FILL_PROFILE')}</div>
              </button>
            }
            {album.status === EReviewStatus.Draft &&
              <button type='button' className={'btn btn-sm btn-primary'}
                      onClick={reviewAlbum}>{t('SUBMIT_FOR_REVIEW')}</button>
            }
            {album.batch
              ?
              <div className="albums-list-item-contract">
                {!album.batch.contractOne && <button type='button' className={'btn btn-blue btn-sm'}
                                                     onClick={signContract}>
                  <div>{t('SIGN_CONTRACT')}</div>
                </button>}

                {(album.batch.contractTwo) && <button
                  type='button'
                  className={'btn btn-blue btn-sm'}
                  onClick={(e) => {
                    e.stopPropagation();
                    download(album.batch?.contractTwo!)
                  }}
                >
                  <div>{t('DOWNLOAD_AGREEMENT')}</div>
                </button>}
              </div>
              :
              null
            }
          </div>
        </div>
      </div>
    </OutsideClick>
  );
}

export default AlbumsListItem;