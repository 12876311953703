import React, {FC, InputHTMLAttributes} from 'react';

import {useTranslation} from "react-i18next";
import {ReactSVG} from "react-svg";

interface Props extends InputHTMLAttributes<any> {
  label?: string;
  description?: string;
  className?: string;
  classNameWrap?: string;
  classNameInput?: string;
  icon?: any
}

const Input: FC<Props> = ({
                            label,
                            description,
                            className,
                            classNameWrap,
                            classNameInput,
                            icon,
                            ...props
                          }) => {
  const {t} = useTranslation();
  return (
    <div className={`form-group ${className || ''}`}>
      {label ?
        <label className={`text-dark mb-2`}>
          <span>{t(label)}</span>
          {props.required ? <span className='text-danger text-12'>*</span> : null}
        </label>
        : null
      }
      {description ?
        <div className={`text-dark-14 mb-2 text-pre-wrap`}>{t(description)}</div>
        : null
      }
      <div className={`form-control-wrap ${className || ''}`}>
        <textarea className={`form-control${icon ? ' form-control-icon' : ''} ${classNameInput || ''}`} {...props} />
        <div className="form-control-border" />
        <div className="form-control-gradient" />
        {icon ? <div className='input-icon'><ReactSVG src={icon}/></div> : null}
      </div>
    </div>
  );
}

export default Input;