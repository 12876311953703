import React, {FC, useCallback} from 'react';
import {useTranslation} from "react-i18next";
import Loadable from "../../components/Loadable";
import {ReactSVG} from "react-svg";
import hooray from './assets/star.svg';
import arrow_svg from "../../assets/images/ArrowLeft.svg";
import check_svg from "../../assets/images/check.svg";
import button from "../../components/Button";
import {observer, useLocalObservable} from 'mobx-react';
import {
  ECurrency, IAsset,
  IPerformer,
  IUpdatePerformerRequest
} from "../../modules/rest";
import {runInAction} from "mobx";
import {API} from "../../modules/api";
import {toast} from "react-toastify";
import {Checkbox, Input, Select} from "../../components/FormControls";
import ComposePerformerTabs from "./ComposePerformerTabs";
import InputFile from "../../components/FormControls/InputFile";
import attach_svg from "../../assets/images/attach.svg";
import {download} from "../../modules/utils";
import close_svg from "../../assets/images/close.svg";

interface State {
  loading: boolean;
  request: IUpdatePerformerRequest;
  bankCard: boolean;
  bankCorrespondent: boolean;
  bankDetailsProof: IAsset | null;
}

interface Props {
  onStepChange: (dir: 1 | -1) => void;
  performer?: Partial<IPerformer>;
  // getPerformer: () => void;
}

const ComposePerformerStep3: FC<Props> = observer(({onStepChange, performer}) => {
  const {t} = useTranslation();

  const st = useLocalObservable<State>(() => ({
    loading: false,
    bankCard: Boolean(performer?.bankDetails?.cardHolder || performer?.bankDetails?.cardNumber),
    bankCorrespondent: Boolean(performer?.bankDetails?.corrBic || performer?.bankDetails?.corrIban || performer?.bankDetails?.corrName),
    bankDetailsProof: performer?.bankDetailsProof ?? null,
    request: {
      bankDetailsProofId: performer?.bankDetailsProof?.id,
      bankDetails: {
        payoneerAccount: performer?.bankDetails?.payoneerAccount || '',
        bankName: performer?.bankDetails?.bankName || '',
        bic: performer?.bankDetails?.bic || '',
        bankAddress: performer?.bankDetails?.bankAddress || '',
        accountHolder: performer?.bankDetails?.accountHolder || '',
        iban: performer?.bankDetails?.iban || '',
        currency: performer?.bankDetails?.currency || ECurrency.EUR,
        cardHolder: performer?.bankDetails?.cardHolder || '',
        cardNumber: performer?.bankDetails?.cardNumber || '',

        corrBic: performer?.bankDetails?.corrBic || '',
        corrIban: performer?.bankDetails?.corrIban || '',
        corrName: performer?.bankDetails?.corrName || '',
      },
    },
  }));

  const handleSubmit = useCallback(async (e: any) => {
    e.preventDefault();
    runInAction(() => (st.loading = true));
    try {
      await API.Performers.updatePerformer(performer?.id!, st.request);
      await API.Performers.reviewPerformer(performer?.id!);
      onStepChange(1);
    } catch (e: any) {
      toast.error(e)
    } finally {
      runInAction(() => (st.loading = false));
    }
  }, [onStepChange, st.request, performer?.id]);

  const btnDisabled =
    !st.request.bankDetails?.accountHolder ||
    !st.request.bankDetails?.bic ||
    !st.request.bankDetails?.iban ||
    !st.request.bankDetails?.currency ||
    !st.request.bankDetails?.bankName ||
    !st.request.bankDetails?.bankAddress ||
    !st.request.bankDetails?.bankAddress ||
    (st.bankCard && (!st.request.bankDetails?.cardNumber || !st.request.bankDetails?.cardHolder)) ||
    (st.bankCorrespondent && (!st.request.bankDetails?.corrBic || !st.request.bankDetails?.corrIban || !st.request.bankDetails?.corrName));

  return (
    <>
      <ComposePerformerTabs
        step={2}
        onPrev={() => onStepChange(-1)}
        onNext={handleSubmit}
        nextDisabled={btnDisabled}
        last/>
      <div className='position-relative'>
        <div className='compose-performer-modal-finish-congr'>
          <ReactSVG src={hooray} className='react-icon'/>
          <div className='compose-performer-modal-finish-congr-text'>
            <div className='bold-24 mb-2'>{t('HOORAY')}</div>
            <div className='bold'>{t('LAST_STEP')}!</div>
          </div>
        </div>
        <Loadable loading={st.loading} className='performer-compose-form'>
          <div className="compose-performer-finish-gradient"/>
          <div className="performer-compose-finish-content">
            <div className='ps-2' style={{paddingBottom: 56}}>
              <h2 className='text-24 mb-2'>{t('PAYMENT_DETAILS_TITLE')}</h2>
              <div className='text-14 text-pre-wrap'
                   style={{color: 'rgba(255,255,255,0.8)'}}>{t('PAYMENT_DETAILS_TEXT')}</div>
            </div>
            <form onSubmit={handleSubmit} className='compose-performer-modal-finish'>
              <div className="row align-items-end">
                <div className="col pe-3">
                  <Input
                    className='mb-3'
                    label={'ACCOUNT_HOLDER'}
                    description={'ACCOUNT_HOLDER_TEXT'}
                    placeholder={'John Doe'}
                    required
                    value={st.request.bankDetails?.accountHolder}
                    onChange={(e) => runInAction(() => (st.request.bankDetails!.accountHolder = e.target.value))}
                  />
                </div>
                <div className="col ps-3">
                  <Input
                    className='mb-3'
                    description={'BIC_TEXT'}
                    label={'BIC'}
                    placeholder={'GRWXBEV1IXX'}
                    required
                    value={st.request.bankDetails?.bic}
                    onChange={(e) => runInAction(() => (st.request.bankDetails!.bic = e.target.value))}
                  />
                </div>
              </div>
              <div className="row align-items-end">
                <div className="col pe-3">
                  <Input
                    className='flex-1'
                    label={'IBAN'}
                    description={'IBAN_TEXT'}
                    placeholder={'BE96 1642 2275 6835'}
                    required
                    value={st.request.bankDetails?.iban}
                    onChange={(e) => runInAction(() => (st.request.bankDetails!.iban = e.target.value))}
                  />
                </div>
                <div className="col ps-3">
                  <Select
                    description={'CURRENCY_TEXT'}
                    label={'CURRENCY'}
                    required
                    value={st.request.bankDetails?.currency}
                    onChange={(e) => runInAction(() => (st.request.bankDetails!.currency = e.target.value))}
                  >
                    {Object.entries(ECurrency).map(([k, v]) => (
                      <option value={v} key={v}>
                        {v}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="row mb-3 align-items-end">
                <div className="col-6 pe-3">
                  <Input
                    label={'BANK_NAME'}
                    description={'BANK_NAME_TEXT'}
                    placeholder={'Transferwise'}
                    required
                    value={st.request.bankDetails?.bankName}
                    onChange={(e) => runInAction(() => (st.request.bankDetails!.bankName = e.target.value))}
                  />
                </div>

              </div>
              <Input
                description='BANK_ADDRESS_TEXT'
                label={'BANK_ADDRESS'}
                placeholder={'Avenue Louise 54, Room S52, Brussels, 1050, Belgium'}
                required
                value={st.request.bankDetails?.bankAddress}
                onChange={(e) => runInAction(() => (st.request.bankDetails!.bankAddress = e.target.value))}
              />
              <div className={`compose-performer-finis-check-wrap${st.bankCard ? ' active' : ''}`}>
                <Checkbox
                  checked={st.bankCard}
                  onChange={(e) => runInAction(() => {
                    st.bankCard = e.target.checked;
                    st.request.bankDetails!.cardNumber = '';
                    st.request.bankDetails!.cardHolder = '';
                  })}
                  label='BANK_CARD'
                  description='BANK_CARD_TEXT'/>
                {st.bankCard &&
                <div className="row mt-3">
                  <div className="col pe-3">
                    <Input
                      className='mb-0'
                      label={'CARD_NUMBER'}
                      placeholder={'5012 3456 7890 1234'}
                      required
                      value={st.request.bankDetails?.cardNumber || ''}
                      onChange={(e) => runInAction(() => (st.request.bankDetails!.cardNumber = e.target.value))}
                    />
                  </div>
                  <div className="col ps-3">
                    <Input
                      className='mb-0'
                      label={'CARD_HOLDER'}
                      placeholder={'JOHN JOE'}
                      required
                      value={st.request.bankDetails?.cardHolder || ''}
                      onChange={(e) => runInAction(() => (st.request.bankDetails!.cardHolder = e.target.value))}
                    />
                  </div>
                </div>
                }
              </div>
              <div className={`compose-performer-finis-check-wrap${st.bankCorrespondent ? ' active' : ''}`}>
                <Checkbox
                  checked={st.bankCorrespondent}
                  onChange={(e) => runInAction(() => {
                    st.bankCorrespondent = e.target.checked;
                    st.request.bankDetails!.corrName = '';
                    st.request.bankDetails!.corrBic = '';
                    st.request.bankDetails!.corrIban = '';
                  })}
                  label='BANK_CORRESPONDENT'
                  description='BANK_CORRESPONDENT_TEXT'/>
                {st.bankCorrespondent &&
                <div className="row mt-3">
                  <div className="col-4">
                    <Input
                      label={'BANK_NAME_ADDRESS'}
                      placeholder={'Transferwise, Avenue Louise 54, Room S52, Brussels, 1050, Belgium'}
                      required
                      value={st.request.bankDetails?.corrName || ''}
                      onChange={(e) => runInAction(() => (st.request.bankDetails!.corrName = e.target.value))}
                    />
                  </div>
                  <div className="col-4">
                    <Input
                      label={'BIC/SWIFT'}
                      placeholder={'TRWIGB21XXX'}
                      required
                      value={st.request.bankDetails?.corrBic || ''}
                      onChange={(e) => runInAction(() => (st.request.bankDetails!.corrBic = e.target.value))}
                    />
                  </div>
                  <div className="col-4">
                    <Input
                      label={'IBAN'}
                      placeholder={'GB98MIDL07009312345678'}
                      required
                      value={st.request.bankDetails?.corrIban || ''}
                      onChange={(e) => runInAction(() => (st.request.bankDetails!.corrIban = e.target.value))}
                    />
                  </div>
                </div>
                }
              </div>
              <div className="row align-items-end  proof-scan-wrap">
                <div className="col-8 pe-3">
                  <Input
                    label={'PAYONEER_ACCOUNT'}
                    description={'PAYONEER_ACCOUNT_TEXT'}
                    placeholder={'john@gmail.com'}
                    type={'email'}
                    value={st.request.bankDetails?.payoneerAccount || ''}
                    onChange={(e) => runInAction(() => (st.request.bankDetails!.payoneerAccount = e.target.value))}
                  />
                </div>
                <div className='col ps-3 proof-scan'>
                  <InputFile
                    description={'PASSPORT_SCAN_INFO'}
                    placeholder={t('SCREEN') || ''}
                    label={'PASSPORT_SCAN'}
                    listFiles={st.bankDetailsProof}
                    onChange={(file) => runInAction(() => {
                      st.bankDetailsProof = file;
                      st.request.bankDetailsProofId = file?.id || '';
                    })}
                    accept=".zip, .pdf, image/jpeg"
                    deletable={false}
                  >
                    <div className='form-file-label w-100'>
                      <ReactSVG src={attach_svg} className='react-icon'/>
                      <span className='ps-2'>{t('UPLOAD_SCAN')}</span>
                    </div>
                  </InputFile>
                  {st.bankDetailsProof
                    ?
                    <div className='form-file-list-item'>
                      <div className='text-truncate'
                           onClick={() => download(st.bankDetailsProof!)}>{st.bankDetailsProof.name}</div>
                      <div
                        className='ms-2 cur-pointer'
                        onClick={(e) => runInAction(() => {
                          e.stopPropagation();
                          st.bankDetailsProof = null;
                          st.request.bankDetailsProofId = '';
                        })}
                      >
                        <ReactSVG src={close_svg}  className='react-icon'/>
                      </div>
                    </div>
                    :
                    null
                  }
                </div>
              </div>
              <div className="modal-footer-btns">
                <button className='btn btn-lg btn-third flex-1 me-3' type='button' onClick={() => onStepChange(-1)}>
                  <div className='w-100 d-flex align-items-center'>
                    <ReactSVG src={arrow_svg} className='position-absolute react-icon' style={{left: 0}}/>
                    <div className='flex-grow-1'>{t('BACK')}</div>
                  </div>
                </button>
                <button className='btn btn-lg btn-primary flex-1 ms-3' type='submit' disabled={btnDisabled}>
                  <ReactSVG src={check_svg} className='position-absolute react-icon'
                            style={{right: 24}}/>
                  {t('DONE')}
                </button>
              </div>

            </form>
          </div>
        </Loadable>
      </div>
    </>
  );
});

export default ComposePerformerStep3;