import {observer, useLocalObservable} from 'mobx-react';
import {
  EFieldGroup,
  ELanguage,
  EMusicMood,
  IAddTrackRequest,
  IAsset, IGenre,
  IPerformer,
  ITrack,
  IUpdateTrackRequest,
  TIdentifier
} from '../../modules/rest';
import {runInAction} from 'mobx';
import Button from '../../components/Button';
import React, {FC, FormEvent, useCallback, useEffect} from 'react';
import Loadable from '../../components/Loadable';
import {API} from '../../modules/api';
import {toast} from 'react-toastify';
import {Languages} from '../../modules/directory';
import {useTranslation} from 'react-i18next';
import {Input, InputFile, Select, Textarea} from '../../components/FormControls';
import {openCoverRequirementsModal} from '../../modals';
import note_svg from '../../assets/images/note.svg';
import micr_svg from '../../assets/images/micr.svg';
import inkwell_svg from '../../assets/images/inkwell.svg';
import author_svg from '../../assets/images/author.svg';
import language_svg from '../../assets/images/language.svg';
import puzzle_svg from '../../assets/images/puzzle.svg';
import music_svg from '../../assets/images/music.svg';
import mask_svg from '../../assets/images/mask.svg';
import text_svg from '../../assets/images/text.svg';
import {useNavigate} from 'react-router-dom';
import CloseBtn from '../../components/CloseBtn';
import cover_bg from '../../assets/images/cover_bg.svg';
import smile_svg from '../../assets/images/smile.svg';
import arrow_svg from '../../assets/images/ArrowLeft.svg';
import chevron_svg from '../../assets/images/chevron.svg';
import langs from '../../translates/langs.en.json';
import {ReactSVG} from "react-svg";
import question_svg from '../../assets/images/question_2.svg';
import session from "../../modules/session";
import moment from "moment/moment";

type TTrackType = 'vocal'|'instrumental';

interface State {
  file: IAsset|null;
  // sample: IAsset|null;
  cover: IAsset|null;
  request: Partial<IAddTrackRequest&IUpdateTrackRequest>;
  loading: boolean;
  // trackType: TTrackType;
  infoVisible?: boolean;
  stageName?: string;
  firstEntry: boolean;
  genreId?: number;
  subGenres: IGenre[];
  genreLoading: boolean
}

interface Props {
  mode: 'add'|'edit';
  albumId?: TIdentifier;
  author: IPerformer;
  track?: Partial<ITrack>;
  title: string;
  isSingle?: boolean;

  onUpdated(): void;
}

const TrackCompose: FC<Props> = observer(({albumId, title, track, author, mode, onUpdated, isSingle}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const st = useLocalObservable<State>(() => ({
    request: {
      albumId: Number(albumId),
      title: track?.title,
      performerId: author.id,
      coverId: track?.album?.cover?.id,
      fileId: track?.file?.id,
      // sampleId: track?.sample?.id,
      lyrics: track?.lyrics || '',
      authorLyrics: track?.authorLyrics,
      authorMusic: track?.authorMusic,
      feat: track?.feat,
      version: track?.version,
      // genre: track?.genre,
      mood: track?.mood,
      subGenreId: track?.subGenre?.id,
      language: track?.language,
      releasedAt: track?.album?.releasedAt ? moment(track?.album?.releasedAt).format('YYYY-MM-DD') : null,
    },
    stageName: author?.stageName || "",
    file: track?.file || null,
    // sample: track?.sample || null,
    cover: track?.album?.cover || null,
    loading: false,
    genreId: track?.mainGenre?.id,
    genreLoading: false,
    subGenres: [],
    // trackType: track?.language || track?.lyrics || track?.authorLyrics ? 'vocal' : 'instrumental',
    firstEntry: !Boolean(localStorage.getItem('compose-track-entry')),
  }));

  const init = useCallback(() => {
    if (mode === 'add') {
      API.Tracks.getLastPerformerTrack(author.id, [
        EFieldGroup.TrackMood, EFieldGroup.TrackGenre, EFieldGroup.TrackPerformer, EFieldGroup.TrackLanguage, EFieldGroup.TrackEdit,
      ]).then((track) => {
        if (!track) return;
        runInAction(() => {
          st.request = {
            ...st.request,
            authorMusic: track.authorMusic,
            authorLyrics: track.authorLyrics,
            language: track.language,
            subGenreId: track.subGenre?.id,
            mood: track.mood,
          };
        });
      });
    }
    if (st.genreId) getSubgenres(st.genreId, true);
  }, [author.id, mode, st]);

  useEffect(() => {
    init();
  }, []);

  const submit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      runInAction(() => (st.loading = true));
      let promise;
      const request = !author.stageName ? {...st.request, stageName: st.stageName} : st.request;
      if (!albumId) {
        promise = API.Albums.createSingle({single: request as IAddTrackRequest, performerId: author.id});
      } else if (mode === 'add') {
        promise = API.Tracks.addTrack(request as IAddTrackRequest);
      } else {
        promise = API.Tracks.updateTrack(track!.id!, st.request as IUpdateTrackRequest);
      }
      promise
        .then(() => {
          toast.success(t('TRACK_SAVE_TO_DRAFT'));
          onUpdated();
        })
        .catch(toast.error)
        .finally(() => runInAction(() => (st.loading = false)));
    },
    [st, onUpdated, mode, track, t, author.id, albumId]
  );


  const generateCover = useCallback(() => {

  }, [st.request])

  const handleChangeTrackType = useCallback((type: TTrackType) => () => {
    // runInAction(() => {
    //   st.trackType = type;
    //   if (type === 'instrumental') {
    //     st.request.authorLyrics = null;
    //     st.request.lyrics = null;
    //     st.request.language = null;
    //     // st.request.isMusic = true;
    //   }
    // })
  }, []);

  const getSubgenres = useCallback(async (id: number, init?: boolean) => {
    if (!id) {
      st.subGenres = [];
      st.request.subGenreId = undefined;
      return
    }
    try {
      runInAction(() => (st.genreLoading = true));
      const subGenres = await API.Genres.getSubgenres(id);
      runInAction(() => {
        st.subGenres = subGenres;
        if (!init) st.request.subGenreId = undefined;
      });
    } catch (e: any) {
      toast.error(e);
    } finally {
      runInAction(() => (st.genreLoading = false));
    }
  }, []);

  return (
    <Loadable loading={st.loading} className='performer-form'>
      <div className='mb-4 d-flex justify-content-between align-items-center'>
        <div className='d-flex align-items-end'>
          <h4 className='bold-24'>
            {t(title)}
            <span className='text-muted-14 ms-2 text-regular'>{t('TRACK_PERMISSION')}</span>
          </h4>
        </div>
        <CloseBtn onClick={() => navigate('../')}/>
        {/*<Help url='https://docs.google.com/document/d/1O4uwC4q5GXqsh2iev0wOXIKIXLsmi4lt7S12_dgdcrQ' />*/}
      </div>
      {st.firstEntry && mode === 'add' && false
        ?
        <>
          <div className='text-medium text-muted mb-3'>{t('SELECT_TRACK_TYPE')}</div>
          <div className='d-flex'>
            {['instrumental', 'vocal'].map(item => (
              <div className='track-type-first-entry' key={item} onClick={() => runInAction(() => {
                st.firstEntry = false;
                // st.trackType = item as TTrackType;
                localStorage.setItem('compose-track-entry', '1');
              })}>
                <div className="track-type-first-entry-content">
                  <div className='d-flex justify-content-between align-items-center'>
                    <div className='bold mb-2'>{t(item.toUpperCase())}</div>
                    <div className='track-type-first-entry-arrow'><ReactSVG src={arrow_svg}/></div>
                  </div>
                  <div className='text-muted-14'>{t(`${item.toUpperCase()}_TEXT`)}</div>
                </div>
              </div>
            ))}
          </div>
        </>
        :
        <>
          {/*<div className='compose-track-type-btns'>*/}
          {/*  {['instrumental', 'vocal'].map(item => (*/}
          {/*    <button*/}
          {/*      key={item}*/}
          {/*      type='button'*/}
          {/*      onClick={handleChangeTrackType(item as TTrackType)}*/}
          {/*      className={`btn${st.trackType === item ? ' btn-secondary' : ' text-muted'} btn-40 w-auto px-20`}*/}
          {/*    >*/}
          {/*      <div>{t(item.toUpperCase())}</div>*/}
          {/*    </button>*/}
          {/*  ))}*/}
          {/*  <Info>*/}
          {/*    <div className='bold mb-2'>{t('INSTRUMENTAL')}</div>*/}
          {/*    <div className='text-muted-14'>{t('INSTRUMENTAL_TEXT')}</div>*/}
          {/*    <hr/>*/}
          {/*    <div className='bold mb-2'>{t('VOCAL')}</div>*/}
          {/*    <div className='text-muted-14'>{t('VOCAL_TEXT')}</div>*/}
          {/*  </Info>*/}
          {/*</div>*/}
          <form onSubmit={submit}>
            <div className='row mb-4'>
              <div className='col-4'>
                <Input
                  icon={note_svg}
                  type='text'
                  placeholder={t('TRACK_NAME') || ''}
                  label='TRACK_NAME'
                  value={st.request.title}
                  maxLength={100}
                  onChange={(e) => runInAction(() => (st.request.title = e.target.value || null))}
                  required
                />
              </div>
              <div className='col-4'>
                <Input
                  icon={micr_svg}
                  type='text'
                  placeholder={t('PERFORMER') || ''}
                  label='PERFORMER'
                  value={st.stageName}
                  maxLength={100}
                  onChange={(e) => runInAction(() => (st.stageName = e.target.value || ''))}
                  disabled={Boolean(author.stageName)}
                  required
                  title={t('PERFORMER_INPUT_TITLE') || ''}
                />

              </div>
              <div className='col-4'>
                <Input
                  icon={author_svg}
                  type='text'
                  placeholder={'John Doe'}
                  label='MUSIC_AUTHOR'
                  value={st.request.authorMusic}
                  maxLength={100}
                  onChange={(e) => runInAction(() => (st.request.authorMusic = e.target.value || null))}
                  required
                />
              </div>
            </div>
            <div className='row'>
              <div className="col-8">
                <div className="row mb-4">
                  <div className='col-12'>
                    <div className='mb-4 input-group'>
                      <Select
                        className='w-50'
                        icon={mask_svg}
                        label='GENRE'
                        required
                        value={st.genreId}
                        onChange={(e) => getSubgenres(e.target.value)}
                      >
                        <option value=''>-- {t('SELECT')} --</option>

                        {session.genres.map(genre => (
                          <option key={genre.id} value={genre.id}>
                            {t(genre.name)}
                          </option>
                        ))}
                      </Select>
                      <Loadable loading={st.genreLoading} className='w-50'>
                        <Select
                          className='w-100'
                          required
                          value={st.request.subGenreId || ''}
                          onChange={(e) => runInAction(() => {
                            st.request.subGenreId = Number(e.target.value);
                            // st.request. = e.target.value || null
                          })}
                        >
                          <option value=''>-- {t('SELECT')} --</option>

                          {st.subGenres.map(genre => (
                            <option key={genre.id} value={genre.id}>
                              {t(genre.name)}
                            </option>
                          ))}
                        </Select>
                      </Loadable>
                    </div>
                  </div>
                  <div className='col-6'>
                    <Select
                      icon={smile_svg}
                      label='MOOD'
                      required
                      value={st.request.mood || ''}
                      onChange={(e) => runInAction(() => (st.request.mood = (e.target.value || null) as EMusicMood))}
                    >
                      <option value=''>-- {t('SELECT')} --</option>

                      {Object.entries(EMusicMood).map(([v, k]) => (
                        <option key={k} value={k}>
                          {t(v)}
                        </option>
                      ))}
                    </Select>
                  </div>
                  <div className='col-6'>
                    <Input
                      required
                      icon={inkwell_svg}
                      type='text'
                      placeholder={'John Doe'}
                      label='LYRICIST'
                      value={st.request.authorLyrics || ''}
                      maxLength={100}
                      onChange={(e) => runInAction(() => (st.request.authorLyrics = e.target.value || null))}
                    />
                  </div>
                </div>

              </div>
              <div className="col-4 mb-4">
                <Textarea
                  required
                  className='h-100'
                  classNameWrap='h-100'
                  classNameInput='h-100'
                  icon={text_svg}
                  type='text'
                  placeholder={t('MUSIC_TEXT') || ''}
                  label='MUSIC_TEXT'
                  value={st.request.lyrics || ''}
                  onChange={(e) => runInAction(() => (st.request.lyrics = e.target.value || null))}
                />
              </div>
            </div>
            <div className="row mb-4">
              <div className='col-4'>
                <Select
                  className='mb-4'
                  required
                  icon={language_svg}
                  label='MUSIC_LN'
                  value={st.request.language || ''}
                  onChange={(e) => runInAction(() => (st.request.language = (e.target.value || null) as ELanguage))}
                >
                  <option value=''>-- {t('SELECT')} --</option>
                  {Object.entries(Languages).map(([k, v]) => (
                    <option key={k} value={k}>
                      {/*@ts-ignore*/}
                      {langs[k]}
                    </option>
                  ))}
                </Select>
                <Input
                  type='date'
                  placeholder={t('SELECT_DATE') || ''}
                  label='RELEASE_DATE'
                  value={st.request.releasedAt || ''}
                  maxLength={100}
                  onChange={(e) => runInAction(() => (st.request.releasedAt = e.target.value || null))}
                />
              </div>
              <div className='col-4 d-flex justify-content-start flex-column'>
                <InputFile
                  fileClassName='h-100'
                  className='h-100'
                  listFiles={st.file}
                  required
                  placeholder={t('TRACK') || ''}
                  acceptText={'.wav'}
                  label={'TRACK'}
                  deletable={false}
                  accept='audio/wav, audio/x-wav'
                  onChange={(upload) =>
                    runInAction(() => {
                      st.request.fileId = upload?.id || '';
                      st.file = upload;
                    })
                  }
                />
              </div>
              {isSingle &&
                  <div className='col-4 position-relative'>
                    <InputFile
                      fileClassName='h-100'
                      className='h-100'
                      icon={cover_bg}
                      listFiles={st.cover}
                      acceptText={'3000х3000.jpg'}
                      label={'COVER'}
                      accept='image/jpeg'
                      deletable
                      onChange={(upload) =>
                        runInAction(() => {
                          st.request.coverId = upload?.id || '';
                          st.cover = upload;
                        })
                      }
                    />
                    {/*{st.request.title && st.request.genre && st.request.mood && !st.cover*/}
                    {/*  ?*/}
                    {/*  <div className='text-gradient cur-pointer track-generate-cover' onClick={generateCover}>*/}
                    {/*    {t('GENERATE_COVER')}*/}
                    {/*  </div>*/}
                    {/*  :*/}
                    {/*  null*/}
                    {/*}*/}
                    <div className='track-cover-requirements' onClick={openCoverRequirementsModal}>
                      <div className="icon-question"/>
                      <span className='ms-1'>{t('COVER_REQUIREMENTS')}</span>
                    </div>
                  </div>
              }
            </div>

            <div className={`additional-information${st.infoVisible ? ' visible' : ''}`} onClick={() => {
              runInAction(() => {
                st.infoVisible = !st.infoVisible;
              })
            }}>
              <div>{t('ADDITIONAL_INFORMATION')}</div>
              <div className="additional-information-separator"/>
              <ReactSVG src={chevron_svg}/>
            </div>
            {st.infoVisible
              ?
              <div className={'row mt-4'}>
                <div className='col-4'>
                  <Input
                    icon={puzzle_svg}
                    type='text'
                    placeholder={t('STARRING_PLACEHOLDER') || ''}
                    label='STARRING'
                    value={st.request.feat || ''}
                    maxLength={100}
                    onChange={(e) => runInAction(() => (st.request.feat = e.target.value || null))}
                  />
                </div>
                <div className='col-4'>
                  <Input
                    icon={music_svg}
                    type='text'
                    placeholder={t('VERSION_PLACEHOLDER') || ''}
                    label='VERSION'
                    value={st.request.version || ''}
                    maxLength={100}
                    onChange={(e) => runInAction(() => (st.request.version = e.target.value || null))}
                  />
                </div>
              </div>
              :
              null
            }
            <div className='performer-form-footer'>
              {/*{st.trackType === 'instrumental'*/}
              {/*  ?*/}
              {/*  <div/>*/}
              {/*  :*/}
              {/*  <div className='d-flex align-items-center flex-grow-1'>*/}
              {/*    <Checkbox*/}
              {/*      label={'ONE_MIN'}*/}
              {/*      checked={st.request.isMusic}*/}
              {/*      onChange={(e) => runInAction(() => (st.request.isMusic = e.target.checked || false))}*/}
              {/*    />*/}
              {/*    <Info type='question'>*/}
              {/*      <p className='text-muted-14' dangerouslySetInnerHTML={{__html: t('ONE_MIN_TRACK_INFO') || ''}}/>*/}
              {/*    </Info>*/}
              {/*  </div>*/}
              {/*}*/}
              <div className='performer-form-footer'>
                <Button type='button' text='CANCEL' color='third' onClick={() => navigate(-1)}/>
                <Button type='submit' text={mode === 'add' ? 'ADD' : "SAVE"}/>
              </div>
            </div>
          </form>
        </>
      }
    </Loadable>
  );
});

export default TrackCompose;
