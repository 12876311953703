import {observer, useLocalObservable} from 'mobx-react';
import {
  IAlbum,
  IAsset,
  ICreateAlbumRequest,
  IPerformer,
  IUpdateAlbumRequest
} from '../../modules/rest';
import {runInAction} from 'mobx';
import Button from '../../components/Button';
import React, {FC, FormEvent, useCallback} from 'react';
import Loadable from '../../components/Loadable';
import {API} from '../../modules/api';
import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';
import {Input, InputFile} from '../../components/FormControls';
import {openCoverRequirementsModal} from '../../modals';
import music_album_svg from '../../assets/images/music_album.svg';
import {useNavigate, useParams} from 'react-router-dom';
import CloseBtn from '../../components/CloseBtn';
import cover_bg from '../../assets/images/cover_bg.svg';
import question_svg from "../../assets/images/question_2.svg";
import {ReactSVG} from "react-svg";
import moment from "moment";

interface State {
  cover: IAsset | null;
  request: Partial<ICreateAlbumRequest & IUpdateAlbumRequest>;
  loading: boolean;
}

interface Props {
  mode: 'add' | 'edit';
  author: IPerformer;
  album?: Partial<IAlbum>;

  onUpdated(album: IAlbum): void;
}

const AlbumCompose: FC<Props> = observer(({album, author, mode, onUpdated}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const params = useParams<{ performerId: string, albumId: string, '*': string }>();
  const st = useLocalObservable<State>(() => ({
    request: {
      title: album?.title,
      isSingle: album?.isSingle,
      coverId: album?.cover?.id,
      performerId: author?.id,
      releasedAt: album?.releasedAt ? moment(album?.releasedAt).format('YYYY-MM-DD') : null,
    },
    cover: album?.cover || null,
    loading: false,
  }));

  const submit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();
      runInAction(() => (st.loading = true));
      try {
        let res;
        if (mode === 'add') {
          res = await API.Albums.createAlbum(st.request as ICreateAlbumRequest);
          toast.success(t('ALBUM_CREATED'));
        } else {
          res = await API.Albums.updateAlbum(album!.id!, st.request as IUpdateAlbumRequest);
          toast.success(t('ALBUM_SAVE_TO_DRAFT'));
        }
        onUpdated(res as IAlbum);
      } catch (e: any) {
        toast.error(e);
      } finally {
        runInAction(() => (st.loading = false))
      }
    },
    [st, onUpdated, mode, album, t]
  );


  const generateCover = useCallback(() => {

  }, [st.request]);


  const handleBack = useCallback(() => {
    if (mode === 'edit') {
      navigate(`/performers/${params.performerId}/albums/${params.albumId}/tracks`)
    } else {
      navigate(-1)
    }
  }, [params, mode])

  return (
    <Loadable loading={st.loading} className='performer-form'>
      <div className='mb-4 d-flex justify-content-between align-items-center'>
        <h4 className='bold-24'>
          {t(mode === 'add' ? 'ADD' : 'EDIT')} {t('ALBUM')}
          <span className='text-muted-14 ms-2 text-regular'>{t('TRACK_PERMISSION')}</span>
        </h4>
        <CloseBtn onClick={handleBack}/>
        {/*<Help url='https://docs.google.com/document/d/1O4uwC4q5GXqsh2iev0wOXIKIXLsmi4lt7S12_dgdcrQ' />*/}
      </div>
      <form onSubmit={submit}>
        <div className='row mb-4'>
          <div className='col-4 pe-3'>
            <div className='position-relative'>
              <InputFile
                deletable
                icon={cover_bg}
                listFiles={st.cover}
                acceptText={'3000х3000.jpg'}
                label={'COVER'}
                accept='image/jpeg'
                onChange={(upload) =>
                  runInAction(() => {
                    st.request.coverId = upload?.id || '';
                    st.cover = upload;
                  })
                }
              />
              <div className='track-cover-requirements' onClick={openCoverRequirementsModal}>
                <div className="icon-question" />
                <span className='ms-1'>{t('COVER_REQUIREMENTS')}</span>
              </div>
            </div>
          </div>
          <div className='col-4 px-3'>
            <Input
              icon={music_album_svg}
              type='text'
              placeholder={t('ALBUM_NAME') || ''}
              label='ALBUM_NAME'
              value={st.request.title}
              maxLength={100}
              onChange={(e) => runInAction(() => (st.request.title = e.target.value || null))}
              required
            />
          </div>
          <div className='col-4 ps-3'>
            <Input
              type='date'
              placeholder={t('SELECT_DATE') || ''}
              label='RELEASE_DATE'
              value={st.request.releasedAt || ''}
              maxLength={100}
              onChange={(e) => runInAction(() => (st.request.releasedAt = e.target.value || null))}
            />
          </div>
        </div>

        <div className='performer-form-footer'>
          <Button type='button'   text='CANCEL' color='third' onClick={() => navigate(-1)}/>
          <Button type='submit'  text={mode === 'add' ? 'ADD_ALBUM' : 'UPDATE_ALBUM'}/>
        </div>
      </form>
    </Loadable>
  );
});

export default AlbumCompose;
