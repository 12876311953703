import packageJson from '../../package.json';
import {makeAutoObservable, runInAction} from 'mobx';
import {API} from './api';
import {toast} from "react-toastify";
import i18n from "i18next";

class Version {
  current: string = packageJson?.version;
  frontend?: string;
  backend?: string;
  upgrade: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  init = (): void => {
    setInterval(this.check, 60000);
    this.check();
  }

  check = (): void => {
    // API.Main.checkVersion(this.current).then((res) => {
    //   runInAction(() => {
    //     this.frontend = res.frontend;
    //     this.backend = res.backend;
    //     this.upgrade = res.upgrade;
    //   })
    //   // if (res.upgrade) {
    //   //   toast.info(i18n.t('UPDATE_RELOAD'), {toastId: 'warning_version', autoClose: false});
    //   // }
    // });
  };
}

export default new Version();
