import React, {FC } from 'react';
import './header.scss';
import session from '../../modules/session';
import {observer} from 'mobx-react';
import Link from '../Link';
import logo_zoundo from '../../assets/images/logo_zoundo.png';
import logo_axtune from '../../assets/images/logo_axtune.svg';
import logo_echotune from '../../assets/images/logo_echotune.png';
import logo_arpeggy from '../../assets/images/logo_arpeggy.png';
import {formatNumber} from '../../modules/utils';
import Notifications from '../Notifications';
import {useTranslation} from 'react-i18next';
import {NavLink} from "react-router-dom";
import HeaderProfile from "./HeaderProfile";
import {service} from "../../modules/api";
import LangSelect from "../LangSelect";
import {EService} from "../../modules/rest";
import WarningLineWithBtn from "../WarningLineWithBtn";


const logos: Partial<{ [LOGO in EService]: any }> = {
  [EService.Zoundo]: {logo: logo_zoundo, style: {}},
  [EService.Axtune]: {logo: logo_axtune, style: {}},
  [EService.Echotune]: {logo: logo_echotune, style: {width: 140, height: 'auto'}},
  [EService.Arpeggy]: {logo: logo_arpeggy},
}

interface Props {

}


const Header: FC<Props> = () => {
  const {t} = useTranslation();

  if (!session.user) return null;
  return (
    <header>
      <div className='d-flex align-items-center h-100 w-100'>
        <Link to='/'>
          <img src={logos[service]?.logo} alt='logo' className='logo' style={logos[service]?.style}/>
        </Link>
        <div className='position-relative flex-grow-1 w-100 align-items-center d-flex'>
          <nav className='nav'>
            <NavLink to={'/performers'}>{t('ALBUMS')}</NavLink>
            <NavLink to={'/documents'}>{t('DOCUMENTS')}</NavLink>
            <NavLink to={'/reports'}>{t('REPORTS')}</NavLink>
          </nav>
        </div>
      </div>
      <div className='d-flex align-items-center ws-nw ms-3'>
        <div className='me-4 d-flex align-items-center me-5'>
          <div>
            <div className='balance'>
              <div className='text-muted-14'>{t('BALANCE')}</div>
            </div>
            <div className='d-flex align-items-center'>
              {/*<span className='bold'>{formatNumber(session.user.balance || 0, 2)} ₽</span>*/}
              <div className='bold'>${formatNumber(session.user.balance || 0, 2)}</div>
            </div>
          </div>
        </div>
        <LangSelect/>
        <Notifications/>
        <HeaderProfile/>

      </div>
    </header>
  );
};


export default observer(Header);
