import React, { FC } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Icon } from './fontawesome';
import { useTranslation } from 'react-i18next';

interface Props {
  icon?: IconProp;
  text?: string;
  title?: string;
  size?: 'sm' | 'lg' | 'md' | 'xs' | 'none';
  color?: 'primary' | 'success' | 'secondary' | 'danger' | 'warning'| 'third';
  outline?: boolean;
  className?: string;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  reverse?: boolean;
  loading?: boolean;

  onClick?(event: React.MouseEvent<HTMLElement>): void;
}

const Button: FC<Props> = ({
                             text,
                             title,
                             icon,
                             className,
                             onClick,
                             size = 'md',
                             color = 'primary',
                             disabled = false,
                             loading = false,
                             outline = false,
                             type = 'button',
                             reverse = false
                           }) => {
  const { t } = useTranslation();
  return (
    <button
      disabled={disabled}
      type={type}
      title={t(title || '') || ''}
      className={`d-flex align-items-center${reverse ? ' flex-row-reverse' : ''} btn-component btn btn-${size} btn${
        outline ? '-outline' : ''
      }-${color} ${className || ''}`}
      onClick={!loading ? (e) => onClick && onClick(e) : () => {
      }}
    >
      <div className='btn-content'>
        {icon && <div className='btn-icon'><Icon icon={icon} fixedWidth className={text ? (reverse ? 'ms-2 ps-1' : 'me-2 pe-1') : ''} /></div>}
        {text && <span>{t(text)}</span>}
      </div>
    </button>
  );
};

export default Button;
