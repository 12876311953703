import React, {FC, useLayoutEffect, useRef, useState} from 'react';
import './info.scss'
import {ReactSVG} from "react-svg";
import info_svg from "../../assets/images/info.svg";
import question_svg from "../../assets/images/question.svg";
import {Icon} from "../fontawesome";

interface Props {
  children: any;
  className?: string;
  type?: 'info' | 'question'

}

const Info: FC<Props> = ({children, type = 'info', className}) => {
  const info: any = useRef(null);
  const infoHidden: any = useRef(null);
  const [translateX, setTranslateX] = useState(0);

  useLayoutEffect(() => {
    const width = window.innerWidth;
    const distance = info.current?.getBoundingClientRect().left;
    const diff = (width - (distance + 390));
    if(diff < 0) setTranslateX(diff - 12)
  }, [])

  return (
    <div className={`info-helper ${className || ''}`} ref={info}>
      {type === 'info' && <ReactSVG src={info_svg}/>}
      {type === 'question' && <ReactSVG src={question_svg}/>}
      <div className="info-helper-hidden" style={{transform: `translateX(${translateX}px)`}} ref={infoHidden}>
        {children}
      </div>
    </div>
  );
}

export default Info;