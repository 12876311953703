import React, {FC} from 'react';
import {useTranslation} from "react-i18next";

interface Props {
  visible?: boolean;
  text: string;
  btnText?: string;
  btnClassName?: string;
  className?: string;
  onClick?: () => void;
  type?: 'info'|'warning'|'star'
}

const WarningLineWithBtn: FC<Props> = ({
                                         btnText,
                                         btnClassName,
                                         className,
                                         text,
                                         visible,
                                         onClick,
                                         type = 'info',
                                       }) => {
  const {t} = useTranslation();
  if (!visible) return null;
  return (
    <div className={className || ''}>
      <div className='warning-line-with-btn'>
        <div className={`warning-line-with-btn-icon ${type}`}/>
        <span className='text-danger text-medium'>{t(text)}</span>
        {onClick
          ?
          <button type='button' onClick={onClick} className={`btn btn-component btn-sm ${btnClassName || 'btn-primary'}`}>
            <div className='btn-content'>
              {t(btnText!)}
            </div>
          </button>
          :
          null
        }
      </div>
    </div>
  );
}

export default WarningLineWithBtn;