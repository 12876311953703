import React, {FC, useCallback, useEffect} from 'react';
import './documents.scss';
import {useTranslation} from "react-i18next";
import ProfileAuthorsList from "../performer/ProfileAuthorsList";
import {observer, useLocalObservable} from "mobx-react";
import {API} from "../../modules/api";
import {
  EFieldGroup,
  IBatch, IGetBatchesRequest,
  IPagedData,
  IPerformer
} from "../../modules/rest";
import {runInAction} from "mobx";
import {toast} from "react-toastify";
import Pagination from "../../components/Pagination";
import moment from "moment";
import {download} from "../../modules/utils";
import {signContractModal, SignSide} from "../../modals/common/ContractModal";
import {Icon} from "../../components/fontawesome";
import Loadable from "../../components/Loadable";
import {ReactSVG} from "react-svg";
import docs_stub from '../../assets/images/docs_stub.svg';
import Loader from "../../components/Loader";
import session from "../../modules/session";
import {Link} from "react-router-dom";
import AuthorStatusIcon from "../tracks/AuthorStatusIcon";

interface State {
  performers?: IPerformer[];
  pager?: IPagedData<IBatch>
  request: IGetBatchesRequest;
  loading?: boolean;
}

const mochaData: IBatch[] = [
  {
    id: 1,
    contractOne: null,
    contractTwo: null,
    contractBlank: null,
    tag: 'asd',
    albums: [],
    contractPeriod: 100,
    createdAt: '2022-10-10'
  }
]


interface Props {
}

const DocumentsPage: FC<Props> = () => {
  const {t} = useTranslation();

  const st = useLocalObservable<State>(() => ({
    loading: true,
    request: {
      page: 1,
      limit: 30,
    },
  }));

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    try {
      runInAction(() => {
        st.loading = true;
      });
      const [performers, batches] = await Promise.all([
        API.Performers.getList({limit: 1000}, [EFieldGroup.PerformerStatus]),
        API.Batches.getBatchesList(st.request, [EFieldGroup.BatchPerformer, EFieldGroup.BatchContract]),
      ]);

      runInAction(() => {
        st.pager = batches;
        st.request.page = batches.page;
        st.request.limit = batches.limit;
        st.performers = performers.data;
      });
    } catch (e: any) {
      toast.error(e);
    } finally {
      runInAction(() => {
        st.loading = false;
      });
    }
  }

  const signContract = useCallback((batch: IBatch) => (e: any) => {
    e.stopPropagation();
    signContractModal(batch, SignSide.One).then(fetch);
  }, [fetch]);

  const handlePerformerClick = useCallback((id: number|null) => {
    runInAction(() => {
      st.request.performerId = id;
      st.request.page = 1;
      fetch();
    });
  }, [fetch]);

  if (!st.pager?.data) return <>
    <h1 className="page-title">
      {t('DOCUMENTS')}
    </h1>
    <Loader/>
  </>

  return (
    <>
      <h1 className="page-title mb-4 pb-2">
        {t('DOCUMENTS')}
      </h1>
      <div className={`performers-header-list`}>
        {st.performers?.map((performer) => (
          <div
            key={performer.id}
            className={`page__group__item${st.request.performerId === Number(performer.id) ? ' active' : ''}`}
            onClick={() => runInAction(() => {
              st.request.performerId = st.request.performerId === performer.id ? null : performer.id;
              fetch()
            })}
          >
            <span className='text-truncate'>{performer.stageName}</span>
            <AuthorStatusIcon status={performer.status} className='page__group__status__item-icon'/>
          </div>
        ))}
      </div>
      {!st.pager?.data?.length
        ?
        <div className='documents-empty'>
          <ReactSVG src={docs_stub}/>
        </div>
        :
        <>
          <div className='table-head ps-4'>
            <div style={{minWidth: 500, width: 500}}>#</div>
            <div style={{minWidth: 300, width: 300}}>{t('DATE')}</div>
            <div style={{minWidth: 300, width: 300}}>{t('STATUS')}</div>
          </div>
          <Loadable loading={st.loading || false} className='pb-4 documents-table'>
            {st.pager?.data?.map(batch => (
              // {mochaData?.map(batch => (
              <div className="card card-active mt-3 py-2 cursor-default" key={batch.id}>
                <div className="card-body d-flex align-items-center ps-4">
                  <div style={{minWidth: 500, width: 500}} className='d-grid overflow-hidden pe-3'>
                    <div className='bold-20'>{batch.tag}</div>
                    <div className='text-dark bold text-truncate' style={{marginTop: 2}}>
                      {batch.performer?.stageName}
                    </div>
                  </div>
                  <div style={{minWidth: 300, width: 300}} className='text-dark text-medium'>
                    {moment(batch.createdAt).format('DD MMMM YYYY')}
                  </div>
                  <div className='flex-grow-1 text-dark text-nowrap doc-status'>
                    {!batch.contractOne && <div className='text-danger text-medium'>{t('NOT_SIGNED')}</div>}
                    {batch.contractTwo && <div className='text-medium text-success'>{t('SIGNED')}</div>}
                    {!batch.contractTwo && batch.contractOne &&
                      <div><Icon icon={['far', 'clock']} className='me-2'/>{t('CONTRACT_WAIT')}</div>}
                  </div>
                  <div className='' style={{minWidth: 250, width: 250}}>
                    {!batch.contractOne && <button type='button' className={'btn btn-primary btn-md w-100'}
                                                   onClick={signContract(batch)}>
                      <div>{t('SIGN_CONTRACT')}</div>
                    </button>}
                    {(batch.contractTwo) && <button
                      type='button'
                      className={'btn  btn-secondary btn-md w-100 ps-2 ms-1'}
                      onClick={() => download(batch.contractTwo!)}
                    >
                      <div>
                        {t('DOWNLOAD')}
                      </div>
                    </button>}
                  </div>
                </div>
              </div>
            ))}
            <Pagination
              className='mb-4'
              textType='DOCUMENTS'
              pager={st.pager}
              onPageChange={(page) => runInAction(() => {
                st.request.page = page;
                fetch();
              })}
              onLimitChange={(limit) => runInAction(() => {
                st.request.page = 1;
                st.request.limit = limit;
                fetch();
              })}
            />
          </Loadable>
        </>
      }
    </>
  );
}

export default observer(DocumentsPage);