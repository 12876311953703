import React, {FC, useState} from 'react';
import {EReviewStatus, IAlbum, TIdentifier} from '../../modules/rest';
import {ReviewStates} from '../../modules/directory';
import {useTranslation} from 'react-i18next';
import CloseBtn from "../../components/CloseBtn";
import {useNavigate} from "react-router-dom";
import AuthorStatusIcon from "../tracks/AuthorStatusIcon";
import {confirmDialog} from "../../modules/confirm";
import {API} from "../../modules/api";
import {toast} from "react-toastify";
import {ReactSVG} from "react-svg";
import arrow_svg from "../../assets/images/ArrowLeft.svg";
import button from "../../components/Button";
import {thumb} from "../../modules/utils";
import Info from "../../components/Info";
import cover_bg from "../../assets/images/cover_bg.svg";

interface Props {
  album?: IAlbum;
  performerId?: string;
  getAlbum: (albumId: TIdentifier) => void;
}

const Album: FC<Props> = ({album, getAlbum, performerId}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const editAlbum = () => {
    if (album?.isSingle) {
      const trackId = album.tracks?.[0]?.id;
      if (trackId) navigate(`${album?.id}/tracks/${trackId}/edit`);
    } else {
      navigate(`${album?.id}/edit/`);
    }
  }

  const reviewAlbum = () => {
    confirmDialog(t(album?.isSingle ? 'SEND_SINGLE_REVIEW' : 'SEND_ALBUM_REVIEW', {title: album?.title}), {confirmText: t('SUBMIT_FOR_REVIEW') || ''}).then(
      (agree) => {
        if (!agree) return;
        setLoading(true);
        API.Albums.reviewAlbum(album?.id!)
          .then(() => {
            toast.success(t('ALBUM_SENT_REVIEW'));
            getAlbum(album?.id!);
          })
          .catch(toast.error)
          .finally(() => setLoading(false));
      }
    );
  }

  const draftAlbum = () => {
    confirmDialog(
      t(album?.isSingle ? 'DRAFT_SINGLE_CONFIRM' : 'DRAFT_ALBUM_CONFIRM', {title: album?.title}),
      {confirmText: t('CONVERT_TO_DRAFT') || ''}
    ).then((agree) => {
      if (!agree) return;
      setLoading(true);
      API.Albums.draftAlbum(album?.id!)
        .then(() => {
          toast.success(t('ALBUM_TO_DRAFT'));
          getAlbum(album?.id!);
          // editAlbum();
        })
        .catch(toast.error)
        .finally(() => setLoading(false));
    });
  }

  const deleteAlbum = () => {
    confirmDialog(t(album?.isSingle ? 'REMOVE_SINGLE_CONFIRM' : 'REMOVE_ALBUM_CONFIRM', {title: album?.title}), {danger: true}).then((agree) => {
      if (!agree) return;
      setLoading(true);
      API.Albums.deleteAlbum(album?.id!)
        .then(() => {
          toast.success(t('ALBUM_DELETED'));
          navigate(`/performers/${performerId}/albums`);
        })
        .catch(toast.error)
        .finally(() => setLoading(false));

    });

  }

  if (!album) return null;

  return (
    <div className='album-head-wrap'>
    <div className={`album-head ${album?.status}`}>
      <div className="album-head-cover">
        {album.cover
          ?
          <img src={thumb(album.cover?.id!, 208)} alt="cover"/>
          :
          <ReactSVG src={cover_bg} className='react-icon'/>
        }
      </div>
      <div className='d-flex py-2 w-100'>
        <div className='album-info'>
          <div className={`d-flex align-items-center text-medium-14  performer-info-status ${album?.status}`}>
            <div style={{height: 24}}>
              <AuthorStatusIcon status={album?.status!} className={'card__item__status-icon me-2'}/>
            </div>
            <div className={`text-medium-14${album.status === EReviewStatus.Reject ? ' text-danger' : ''}`}>
              {t(ReviewStates[album?.status!])}
            </div>
            {album.status === EReviewStatus.Reject
              ?
              <Info className='ms-2' type='question'>
                <div className='text-danger'>{album.rejectReason || ''}</div>
              </Info>
              :
              null
            }
          </div>
          <div className='bold-20 mt-2 text-truncate'>{album?.title}</div>
          <div className='text-medium text-muted'
               style={{paddingTop: 2}}>{album?.isSingle ? t('SINGLE') : `${album.tracksCount} ${t('tracks')}`}</div>
        </div>
      </div>
      <div className='ps-3'>
        <div className='d-flex justify-content-end'>
          {album.status !== EReviewStatus.Approve
            ?
            <div className="btn close-btn me-3" onClick={album.status === EReviewStatus.Draft ? editAlbum : draftAlbum}>
              <div className='icon-edit' />
            </div>
            :
            null
          }
          {album.status !== EReviewStatus.Approve && (
            <button className='btn close-btn me-3' onClick={deleteAlbum}
                    type={'button'}>
              <div className='icon-trash' />
            </button>
          )}
          <CloseBtn onClick={() => navigate(`/performers/${album?.performer?.id}/albums`)}/>
        </div>
        {album.status === EReviewStatus.Draft
          ?
          <button className='btn btn-md btn-secondary mt-3' onClick={reviewAlbum}>
            <ReactSVG src={arrow_svg} className='btn-icon' style={{transform: 'rotate(135deg)'}}/>
            <div className='text-nowrap ps-3 pe-2'>{t('SUBMIT_FOR_REVIEW')}</div>
          </button>
          :
          null
        }
      </div>
    </div>
    </div>
  );
};

export default Album;