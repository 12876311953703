import React, {FC} from 'react';
import Loadable from "../../components/Loadable";
import logo_zoundo from "../../assets/images/logo_zoundo.svg";
import logo_axtune from "../../assets/images/logo_axtune.png";
import logo_echotune from "../../assets/images/logo_echotune.png";
import logo_arpeggy from "../../assets/images/logo_arpeggy.png";
import {service} from "../../modules/api";
import {EService} from "../../modules/rest";

const logos: Partial<{ [LOGO in EService]: any }> = {
  [EService.Zoundo]: logo_zoundo,
  [EService.Axtune]: logo_axtune,
  [EService.Echotune]: logo_echotune,
  [EService.Arpeggy]: logo_arpeggy,
}

interface Props {
  loading: boolean;
  onSubmit: (e: any) => void;
  children: any;
}

const AuthWrap: FC<Props> = ({loading, onSubmit, children}) => {
  return (
    <Loadable loading={loading} >
      <div className="d-flex justify-content-center overflow-hidden" style={{padding: '100px 0'}}>
        <form onSubmit={onSubmit} className="auth-form bg-gradient">
          <div className='auth-form-content'>
            <div className='text-center mb-4 pb-3'>
              <img src={logos[service] || logo_zoundo} alt="logo" className='auth-form-logo'/>
            </div>
            {children}
          </div>
        </form>
      </div>
    </Loadable>
  );
}

export default AuthWrap;