import {EService, RestAPI} from './rest';
import i18next from "i18next";

const availableServices: { [key: string]: string } = {
  ['console.zoundo.com']: 'zoundo',
  ['console.axtune.com']: 'axtune',
  ['console.echotunemusic.com']: 'echotune',
  ['console.arpeggy.com']: 'arpeggy',
}

const isDev = !!window.location.host.match(/^(localhost|127\.|192\.)/);
const domain = window.location.hostname;
const service: EService = isDev ? EService.Zoundo : (availableServices[domain] as EService);
const backendUrl = isDev ? 'http://192.168.1.223:8004' : `https://api.${domain.replace('console.', '')}`;
// const backendUrl =   `http://redstream.by:8004`;
// const backendUrl =   `https://api.zoundo.com`;
const API = new RestAPI(backendUrl, isDev);

API.setHeadersHandler((headers) => {
  headers['Accept-Language'] = i18next.language;
});

export {API, isDev, backendUrl, domain, service};
