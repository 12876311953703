import React, {FC, useEffect} from 'react';
import {Link, Navigate, Route, Routes, useNavigate, useParams} from "react-router-dom";
import Button from "../../components/Button";
import {useTranslation} from "react-i18next";
import './tracks.scss'
import {ReactSVG} from "react-svg";
import ManageTracks from "./manage";
import TrackCompose from "./compose";
import {EReviewStatus, IAlbum, IPerformer, TIdentifier} from "../../modules/rest";
import Edit from "./edit";
import Loader from "../../components/Loader";
import arrow_swg from '../../assets/images/ArrowLeft.svg';
import {thumb} from "../../modules/utils";
import cover_bg from "../../assets/images/cover_bg.svg";

interface Props {
  performer: IPerformer;
  getAlbum: (albumId: TIdentifier) => void;
  album?: IAlbum;
}

const TracksPage: FC<Props> = ({performer, getAlbum, album}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const params = useParams<{ performerId: string, albumId: string, '*': string }>();

  useEffect(() => {
    getAlbum(params?.albumId!);
  }, []);

  if (!performer || !album) return <Loader/>;
  return (
    <div>
      <div className='breadcrumbs mt-4 '>
        <Link to={'../'}>{t('ALBUMS')}</Link>
        <span> / </span>
        <span className='current'>{album.title || ''}</span>
      </div>
      <div className='d-flex my-4 justify-content-between align-items-center'>
        <div className='d-flex align-items-center' style={{height: 48}}>
          <Link to={'../'} className='btn close-btn me-3'>
            <ReactSVG src={arrow_swg}/>
          </Link>
          <div className='track-album-cover'>
            {album.cover
              ?
              <img src={thumb(album.cover?.id!, 80)} alt="cover"/>
              :
              <ReactSVG src={cover_bg} className='react-icon position-absolute w-100' />
            }
          </div>
          <div className='bold-20 ps-2'>{album.title}</div>
        </div>
        {!params['*'] && !album?.isSingle && album?.status === EReviewStatus.Draft
          ?
          <Button
            text='ADD_TRACK'
            color={'third'}
            icon={['fas', 'plus']}
            onClick={() => navigate('add/')}
          />
          : null
        }
      </div>
      <Routes>
        <Route path='/' element={<ManageTracks albumId={Number(params.albumId)} album={album}  />}/>
        <Route path='/add/' element={
          <TrackCompose
            title={'ADD_TRACK'}
            mode='add'
            author={performer}
            albumId={params.albumId as TIdentifier}
            onUpdated={() => {
              navigate(-1)
            }}
          />
        }/>
        <Route path='/:track/edit/' element={<Edit author={performer} albumId={params.albumId as TIdentifier} album={album} getAlbum={getAlbum} />}/>
        <Route path='*' element={<Navigate to='../../' replace/>}/>
      </Routes>
    </div>
  );
}

export default TracksPage;