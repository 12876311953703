import {makeAutoObservable, runInAction} from 'mobx';
import {EFieldGroup, IGenre, IUser} from './rest';
import {API} from './api';
import version from './version';

class Session {
  public user: IUser|null = null;
  public ready: boolean = false;
  public genres: IGenre[] = [];

  constructor() {
    version.init();
    const m = window.location.pathname.match(/^\/as\/([0-9A-z\.\-\_=]+)$/);
    if (m) {
      localStorage.setItem('token', m[1]);
    }
    makeAutoObservable(this);
    setInterval(this.fetch, 60000);

    this.fetch().finally(() => runInAction(() => (this.ready = true)));
  }

  fetch = (): Promise<any> => {
    const token = window.localStorage.getItem('token');
    if (token) {
      API.setToken(token);
      return API.Users.getMe([EFieldGroup.UserBalance])
        .then((res) => {
          // API.setToken(res.token);
          // window.localStorage.setItem('token', res.token);
          this.getGenres();
          runInAction(() => (this.user = res));
        })
        .catch(() => {
          if (API.getStatusCode() === 401) {
            API.setToken(null);
            window.localStorage.removeItem('token');
            runInAction(() => (this.user = null));
          }
        });
    } else {
      return Promise.resolve();
    }
  };
  getGenres = async () => {
    try {
      const genres = await API.Genres.getGenres();
      runInAction(() => {
        this.genres = genres;
      });
    } catch (e) {

    }
  }

  logout = (): void => {
    if (!this.user) return;
    API.setToken(null);
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('email');
    runInAction(() => {
      this.user = null;
    });
  };


}

export default new Session();
