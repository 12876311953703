import {useNavigate, useParams} from 'react-router-dom';
import {EFieldGroup, EReviewStatus, IAlbum, IPerformer, ITrack, TIdentifier} from '../../modules/rest';
import TrackCompose from './compose';
import {FC, useEffect, useState} from 'react';
import {API} from '../../modules/api';
import {toast} from 'react-toastify';
import Loader from '../../components/Loader';
import {useTranslation} from "react-i18next";

interface Props {
  author: IPerformer;
  albumId?: TIdentifier;
  album?: IAlbum;
  getAlbum: (albumId: TIdentifier) => void;
}

const EditTrack: FC<Props> = ({ author, albumId, album,getAlbum }) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const params = useParams<{ track: string }>();

  const [track, setTrack] = useState<ITrack>();

  useEffect(() => {
    API.Tracks.getTrack(params.track!, [EFieldGroup.TrackEdit, EFieldGroup.TrackStatus, EFieldGroup.TrackAlbum])
      .then((track) => {
        if (track.status === EReviewStatus.Draft) {
          setTrack(track);
        } else {
          throw new Error(t('EDIT_ONLY_DRAFT') || '');
        }
      })
      .catch((e) => {
        toast.error(e);
        navigate(-1);
      });
  }, [params.track, navigate, t]);

  if (!track) return <Loader />;
  return (
    <div>
        <TrackCompose
          isSingle={album?.isSingle}
          title={album?.isSingle ? 'EDIT_SINGLE' :'EDIT_TRACK'}
          albumId={albumId}
          mode="edit"
          track={track}
          author={author}
          onUpdated={() => {
            getAlbum(albumId!);
            navigate('../');
          }}
        />
      {/*</div>*/}
    </div>
  );
};

export default EditTrack;
