import React, { FC } from 'react';
import './avatar.scss';
import { IPerformer } from '../../modules/rest';

interface Props {
  letter?: string | null;
  className?: string;
  author?: IPerformer;
}

const Avatar: FC<Props> = ({ letter, author, className }) => {

  return (
    <div className={`avatar ${className || ''}`}>
      <div className='avatar-logo'>
        {letter?.[0] || ''}
      </div>
      {author
        ?
        <div className='ps-2 ms-1 overflow-hidden d-grid' style={{minWidth: 100}}>
          <div className='bold-20 text-truncate'>{author.stageName}</div>
          <div className='muted bold text-truncate'>
            {author.lastName} {author.firstName}
          </div>
        </div>
        : null
      }
    </div>
  );
};

export default Avatar;