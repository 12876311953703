import React, {FC} from 'react';
import close from '../assets/images/close.svg'
import { ReactSVG } from 'react-svg';

interface Props {
  onClick: (e: any) => void;
  className?: string;
}

const CloseBtn: FC<Props> = ({onClick, className}) => {

  return (
    <button type='button' className={`btn close-btn ${className || ''}`} onClick={onClick}>
      <div><ReactSVG src={close} /></div>
    </button>
  );
}

export default CloseBtn;