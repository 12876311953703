import {observer, useLocalObservable} from 'mobx-react';
import {EFieldGroup, EReviewStatus, IAlbum, IGetTracksRequest, IPagedData, ITrack} from '../../modules/rest';
import Loadable from '../../components/Loadable';
import React, {FC, useCallback, useEffect} from 'react';
import {runInAction} from 'mobx';
import {API} from '../../modules/api';
import {toast} from 'react-toastify';
import Empty from '../../components/Empty';
import {TrackTitle} from '../../components/Track';
import {useNavigate} from 'react-router-dom';
import {ReviewStates} from '../../modules/directory';
import {confirmDialog} from '../../modules/confirm';
import {useTranslation} from 'react-i18next';
import AuthorStatusIcon from './AuthorStatusIcon';
import Player from "../../components/Player";

interface State {
  pager?: IPagedData<ITrack>;
  request: IGetTracksRequest;
  loading: boolean;
  activeTrackId?: number;
}

interface Props {
  albumId: number;
  album?: IAlbum;
}

const ManageTracks: FC<Props> = observer(({albumId, album}) => {
  const {t} = useTranslation();
  const st = useLocalObservable<State>(() => ({
    loading: true,
    request: {
      albumId: albumId,
      page: 1,
      limit: 1000
    },
    author: null,
  }));

  const fetch = useCallback(() => {
    runInAction(() => (st.loading = true));
    API.Tracks.getTracksList(st.request, [
      EFieldGroup.TrackStatus,
      EFieldGroup.TrackFile,
      EFieldGroup.TrackSample,
      EFieldGroup.BatchContract,
    ])
      .then((pager) => {
        runInAction(() => {
          st.pager = pager;
          st.request.page = pager.page;
          st.request.limit = pager.limit;
        });
      })
      .catch(toast.error)
      .finally(() => runInAction(() => (st.loading = false)));
  }, [st.request]);

  const navigate = useNavigate();

  const editTrack = useCallback(
    (track: ITrack) => {
      navigate(`${track.id}/edit/`);
    },
    [navigate]
  );

  const deleteTack = useCallback(
    (track: ITrack) => {
      confirmDialog(t('REMOVE_TRACK_CONFIRM', {title: track.title}), {danger: true}).then((agree) => {
        if (!agree) return;
        runInAction(() => (st.loading = true));
        API.Tracks.deleteTrack(track.id)
          .then(() => {
            toast.success(t('TRACK_DELETED'));
            fetch();
          })
          .catch(toast.error)
          .finally(() => runInAction(() => (st.loading = false)));
      });
    },
    [fetch]
  );


  const draftTack = useCallback(
    (track: ITrack) => {
      confirmDialog(
        t('DRAFT_TRACK_CONFIRM', {title: track.title}),
        {confirmText: t('CONVERT_TO_DRAFT') || ''}
      ).then((agree) => {
        if (!agree) return;
        runInAction(() => (st.loading = true));
        API.Tracks.draftTrack(track.id)
          .then(() => {
            toast.success(t('TRACK_TO_DRAFT'));
            fetch();
            editTrack(track);
          })
          .catch(toast.error)
          .finally(() => runInAction(() => (st.loading = false)));
      });
    },
    [fetch]
  );

  useEffect(() => {
    runInAction(() => {
      st.request.albumId = albumId;
      st.request.page = 1;
    });
    fetch();
  }, [albumId, album?.status]);

  return (
    <Loadable loading={st.loading} className='pb-3'>
      <div className='d-flex align-items-center text-muted-14 pb-2'>
        <div style={{width: 50}}>#</div>
        <div className='d-flex w-100 justify-content-between'>
          <div className='w-80'>{t('TRACK_NAME')}</div>
          <div className='text-right w-20'>{t('STATUS')}</div>
        </div>
      </div>
      <div className='separator'></div>
      {st.pager && (
        <div className='tracks mt-3'>
          {st.pager.data.map((track) => (
            <div key={track.id} className={`tracks-list-item${st.activeTrackId === track.id ? ' active' : ''}`}>
              <div className='card card-active w-100'>
                <div className='card-body d-flex align-items-center pe-4 justify-content-between'>
                  <div className='w-30 d-flex align-items-center pe-3'>
                    <Player
                      track={track}
                      active={st.activeTrackId === track.id}
                      onChange={(activeTrackId) => runInAction(() => (st.activeTrackId = activeTrackId))}
                    />
                    <TrackTitle
                      track={track}
                      // active={st.activeTrackId === track.id}
                      width={200}
                      className={'ps-3'}
                    />
                  </div>
                  <div className='align-self-center d-flex align-items-center'>
                    <div className={'tracks-list-item-controls'}>
                      {album?.status === EReviewStatus.Draft
                        ?
                        <>
                          {[EReviewStatus.Review, EReviewStatus.Reject].includes(track.status!) && (
                            <button className='btn btn-third btn-40 ms-3' onClick={() => draftTack(track)}
                                    type={'button'}>
                              <div className='icon-edit' />
                            </button>
                          )}
                          {track.status === EReviewStatus.Draft && (
                            <button className='btn btn-third btn-40 ms-3' onClick={() => editTrack(track)}
                                    type={'button'}>
                              <div className='icon-edit' />
                            </button>
                          )}
                          {(track.status !== EReviewStatus.Approve && !album?.isSingle) && (
                            <button className='btn btn-third btn-40 ms-3' onClick={() => deleteTack(track)}
                                    type={'button'}>
                              <div className='icon-trash' />
                            </button>
                          )}
                        </>
                        :
                        null
                      }

                      <div className='me-2 ms-4 text-right'>
                        <div className={`text-status ${track.status}`}>{t(ReviewStates[track.status!])}</div>
                        {track.rejectReason &&
                          <div className='text-danger text-12 mt-1'
                               style={{maxWidth: 200}}>{track.rejectReason}</div>}
                      </div>
                    </div>
                    <AuthorStatusIcon status={track.status!} className='card__item__status-icon'/>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      <Empty show={!st.loading && !st.pager?.count} text='NO_TRACK'/>
      {/*<Pagination*/}
      {/*  pager={st.pager}*/}
      {/*  onPageChange={(page) =>*/}
      {/*    runInAction(() => {*/}
      {/*      st.request.page = page;*/}
      {/*      fetch();*/}
      {/*    })*/}
      {/*  }*/}
      {/*  onLimitChange={(limit) => {*/}
      {/*    runInAction(() => {*/}
      {/*      st.request.page = 1;*/}
      {/*      st.request.limit = limit;*/}
      {/*      fetch();*/}
      {/*    });*/}
      {/*  }}*/}
      {/*/>*/}
    </Loadable>
  );
});

export default ManageTracks;
