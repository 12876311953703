import React, {FC, useCallback} from 'react';
import {useTranslation} from "react-i18next";
import Loadable from "../../components/Loadable";
import {ReactSVG} from "react-svg";
import arrow_svg from "../../assets/images/ArrowLeft.svg";
import button from "../../components/Button";
import {observer, useLocalObservable} from 'mobx-react';
import {
  ECountry, IAsset,
  ICreatePerformerRequest,
  IPerformer,
  IUpdatePerformerRequest
} from "../../modules/rest";
import {runInAction} from "mobx";
import {API} from "../../modules/api";
import {toast} from "react-toastify";
import {Input, Select, Textarea} from "../../components/FormControls";
import {Countries} from "../../modules/directory";
import countries from "../../translates/countries.en.json";
import phone from './assets/phone.png';
import ProfileGallery from "./ProfileGallery";
import ComposePerformerTabs from "./ComposePerformerTabs";

interface State {
  loading: boolean;
  request: IUpdatePerformerRequest;
  photos: IAsset[];
}

interface Props {
  onStepChange: (dir: 1|-1) => void;
  // getPerformer: () => void;
  performer?: Partial<IPerformer>;
}

const ComposePerformerStep1: FC<Props> = observer(({onStepChange, performer}) => {
  const {t} = useTranslation();

  const st = useLocalObservable<State>(() => ({
    loading: false,
    request: {
      nationality: performer?.nationality!,
      firstName: performer?.firstName ?? '',
      middleName: performer?.middleName ?? '',
      lastName: performer?.lastName ?? '',
      stageName: performer?.stageName ?? '',
      phoneNumber: performer?.phoneNumber ?? '',
      similar: performer?.similar ?? '',
      bio: performer?.bio ?? '',
    },
    photos: performer?.photos || []
  }));


  const handleSubmit = useCallback(async (e: any) => {
    e?.preventDefault();
    runInAction(() => (st.loading = true));
    try {
      const photoIds = st.photos.map(item => item.id);
      const request = {...st.request, photoIds}
      await API.Performers.updatePerformer(performer?.id!, request);
      // await getPerformer();
      onStepChange(1);
    } catch (e: any) {
      toast.error(e)
    } finally {
      runInAction(() => (st.loading = false));
    }
  }, [onStepChange, st.request, st.photos, performer?.id]);


  const btnDisabled = !st.request.firstName || !st.request.nationality || !st.request.lastName || !st.request.stageName || !st.request.bio;

  return (
    <>
      <ComposePerformerTabs
        step={0}
        prevDisabled
        nextDisabled={btnDisabled}
        onPrev={() => onStepChange(1)}
        onNext={handleSubmit}
      />
      <Loadable loading={st.loading} className='performer-compose-form'>
        <h4 className='bold-24 mb-4'>{t('PERSONAL_INFO')}</h4>
        <form onSubmit={handleSubmit}>
          <Input
            label={'NICKNAME'}
            description={'NICKNAME_TEXT'}
            className={'mb-3'}
            placeholder={t('NICKNAME_PLACEHOLDER') || ''}
            required
            value={st.request.stageName}
            maxLength={50}
            onChange={(e) => runInAction(() => (st.request.stageName = e.target.value))}
          />

          <Select
            label={'CITIZENSHIP'}
            className={'mb-3'}
            required
            value={st.request.nationality || ''}
            onChange={(e) => runInAction(() => (st.request.nationality = e.target.value as ECountry))}
          >
            <option value=''>-- {t('SELECT')} --</option>
            {/*<option value="ru">{t(`countries:${ECountry.RU}`)}</option>*/}
            {/*<option value="by">{t(`countries:${ECountry.BY}`)}</option>*/}
            {/*<option value="kz">{t(`countries:${ECountry.KZ}`)}</option>*/}
            {/*<option value="ua">{t(`countries:${ECountry.UA}`)}</option>*/}
            {/*<option value="-" disabled>*/}
            {/*  -------*/}
            {/*</option>*/}
            {Object.entries(Countries).map(([k, v]) => (
              <option value={k} key={k}>
                {/*@ts-ignore*/}
                {countries[k]}
              </option>
            ))}
          </Select>

          <div className='d-flex'>
            <div className='flex-grow-1 pe-3'>
              <label>{t('PROFILE_PHOTOS')}</label>
              <div className='text-dark-14 mb-3'>{t('PROFILE_PHOTOS_TEXT')}</div>
              <ProfileGallery
                onChange={(photo => {
                  if (!photo) return;
                  runInAction(() => {
                    st.photos = [...st.photos, photo];
                  });
                })}
                onRemove={(id) => {
                  runInAction(() => {
                    st.photos = st.photos.filter(item => item.id !== id);
                  });
                }}
                photos={st.photos}
              />
              <Textarea
                description={'LINKS_COMMUNITY_TEXT'}
                label={'LINKS_COMMUNITY'}
                className={'mb-3'}
                placeholder={t('LINKS_COMMUNITY_PLACEHOLDER') || ''}
                required
                value={st.request.bio}
                maxLength={512}
                onChange={(e) => runInAction(() => (st.request.bio = e.target.value))}
              />
            </div>
            <div className='performer-compose-example'>
              <div className='text-dark-14 mb-2'>{t('EXAMPLE')}</div>
              <div className='performer-compose-phone' />
            </div>
          </div>

          <Input
            description='SIMILAR_LABEL_TEXT'
            label={'SIMILAR_LABEL'}
            className={'mb-3'}
            value={st.request.similar || ''}
            maxLength={50}
            onChange={(e) => runInAction(() => (st.request.similar = e.target.value))}
          />

          <Input
            description={'SURNAME_TEXT'}
            label={'SURNAME'}
            className={'mb-3'}
            placeholder={t('SURNAME_PLACEHOLDER') || ''}
            required
            value={st.request.lastName}
            maxLength={50}
            onChange={(e) => runInAction(() => (st.request.lastName = e.target.value))}
          />
          <Input
            description='GIVEN_NAMES_TEXT'
            label={'GIVEN_NAMES'}
            className={'mb-3'}
            placeholder={t('FIRST_NAME_PLACEHOLDER') || ''}
            required
            value={st.request.firstName}
            maxLength={50}
            onChange={(e) => runInAction(() => (st.request.firstName = e.target.value))}
          />
          <Input
            label={'PATRONYMIC'}
            className={'mb-3'}
            placeholder={t('PATRONYMIC_PLACEHOLDER') || ''}
            // required
            value={st.request.middleName || ''}
            maxLength={50}
            onChange={(e) => runInAction(() => (st.request.middleName = e.target.value))}
          />

          <Input
            type="tel"
            label={'PHONE'}
            className={'mb-3'}
            placeholder={t('PHONE_PLACEHOLDER') || ''}
            required
            value={st.request.phoneNumber}
            maxLength={50}
            onChange={(e) => runInAction(() => (st.request.phoneNumber = e.target.value))}
          />

          <div className="modal-footer-btns">
            <div className='flex-1 me-3' style={{padding: 1}}/>
            {/*<Button text="CANCEL" color="secondary" className="ms-2" outline onClick={() => props.resolve(false)}/>*/}
            <button className='btn btn-lg btn-primary flex-1 ms-3 p-0' type='submit' disabled={btnDisabled}>
              <ReactSVG src={arrow_svg} className='position-absolute react-icon'
                        style={{transform: 'rotate(180deg)', right: 24}}/>
              {t('NEXT')}
            </button>
          </div>
        </form>
      </Loadable>
    </>
  );
});

export default ComposePerformerStep1;