import React, {FC, useCallback, useEffect, useState} from 'react';
import './assets/performer-compose.scss';
import {useTranslation} from "react-i18next";
import ComposePerformerStep1 from "./ComposePerformerStep1";
import ComposePerformerStep2 from "./ComposePerformerStep2";
import ComposePerformerStep3 from "./ComposePerformerStep3";
import {Link, useNavigate, useParams} from "react-router-dom";
import {API} from "../../modules/api";
import Loader from "../../components/Loader";
import {EFieldGroup, EReviewStatus, IPerformer} from "../../modules/rest";
import {toast} from "react-toastify";

interface Props {
}

const PerformerComposePage: FC<Props> = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [performer, setPerformer] = useState<IPerformer>();
  const params = useParams<{ id: string }>();

  useEffect(() => {
    getPerformer()
  }, [params.id]);

  const getPerformer = useCallback(async () => {
    try {
      const res = await API.Performers.getPerformer(params.id!, [EFieldGroup.PerformerEdit]);
      if (res.status === EReviewStatus.Approve) return navigate(`/performers/${params.id}/albums`);
      if (res.status !== EReviewStatus.Draft) await API.Performers.draftPerformer(res.id);
      setPerformer(res);
    } catch (e: any) {
      toast.error(e);
    }
  }, [params.id]);

  const handleStepChange = useCallback((dir: number) => {
    if (step === 2 && dir === 1) {
      toast.success(t('SUCCESS_SAVE_PROFILE_AND_SEND'));
      navigate(`/performers/${params.id}/albums`);
      return;
    }
    setStep(prevState => prevState + dir);
  }, [step]);

  if (!performer) return (
    <>
      <h1 className="page-title text-center text-gradient mb-4">{t('PROFILE_COMPLETION')}</h1>
      <Loader/>
    </>
  )

  return (
    <>
      <div className='breadcrumbs d-flex mb-2 pb-1'>
        <Link to={`/performers/${params.id}/albums`}>{t('ALBUMS')}</Link>
        <span className='px-1'>/</span>
        <div className='text-tint'>{t('PROFILE_COMPLETION')}</div>
      </div>
      <div className='performer-compose'>
        <h1 className="page-title text-center text-gradient mb-4">{t('PROFILE_COMPLETION')}</h1>
        {step === 0 &&
          <ComposePerformerStep1 onStepChange={handleStepChange} performer={performer}/>}
        {step === 1 &&
          <ComposePerformerStep2 onStepChange={handleStepChange} performer={performer}/>}
        {step === 2 &&
          <ComposePerformerStep3 onStepChange={handleStepChange} performer={performer}/>}
      </div>
    </>
  );
}

export default PerformerComposePage;