import React, {FC, useState} from 'react';
import {useTranslation} from "react-i18next";
import {ReactSVG} from "react-svg";
import lang_svg from '../../assets/images/lang.svg';
import chevron_svg from '../../assets/images/chevron-bottom.svg';

import './lang_select.scss';
import OutsideClick from "../OutsideClick";
import {availableLanguages} from "../../i18n";
import moment from "moment/moment";

interface Props {
}

const LangSelect: FC<Props> = () => {
  const {t, i18n} = useTranslation();

  const [visible, setVisible] = useState(false);

  const showList = () => setVisible(true);
  const hideList = () => setVisible(false);

  const handleClickLang = (ln: string) => () => {
    localStorage.setItem('ln', ln);
    i18n.changeLanguage(ln);
    if(ln === 'ar') {
      document.querySelector("html")?.setAttribute("dir", 'rtl');
    } else {
      document.querySelector("html")?.setAttribute("dir", 'ltr');
    }
    setVisible(false);
  };

  const activeLn = i18n.language;

  if (availableLanguages.length === 1) return null;
  return (
    <OutsideClick outsideClickEvent={hideList} className={`lang-select${visible ? ' opened' : ''}`}>
      <div className='lang-select-visible' onClick={showList}>
        <div className='lang-select-icon'><ReactSVG src={lang_svg}/></div>
        <div className='lang-select-ln'>{activeLn}</div>
        <div className='lang-select-arrow'><ReactSVG src={chevron_svg}/></div>
      </div>
      <div className='custom-search-select-list'>
        {availableLanguages.map((ln, i) => (
          <div
            key={i}
            onClick={handleClickLang(ln)}
            className={`custom-search-select-list-item${ln === activeLn ? ' active' : ''}`}
          >
            {ln}
          </div>
        ))}
      </div>
    </OutsideClick>
  );
}

export default LangSelect;