import React, {FC, useCallback} from 'react';
import {EReviewStatus, IPerformer} from '../../modules/rest';
import {ReviewStates} from '../../modules/directory';
import AuthorStatusIcon from './AuthorStatusIcon';
import {useTranslation} from 'react-i18next';
import Avatar from '../../components/Avatar';
import {useNavigate} from "react-router-dom";
import {ReactSVG} from "react-svg";
import edit from '../../assets/images/edit.svg';
import {toast} from "react-toastify";

interface Props {
  author: IPerformer;
  className?: string;
  editable?: boolean;
}

const Author: FC<Props> = ({author, className, editable = true}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const handleEdit = useCallback(async () => {
    try {
      navigate(`/performer/${author.id}`);
    } catch (e: any) {
      toast.error(e)
    }

  }, [author.status, author.id, navigate]);

  // author.status = EReviewStatus.Approve

  return (
    <div className={`performer-wrap ${author.status} ${className || ''}`}>
      <div className='performer'>
        <Avatar letter={author.stageName} className='align-self-start'/>
        <div className='d-flex py-2 pe-3 w-100'>
          <div className='performer-info'>
            <div className={`d-flex align-items-center performer-info-status ${author.status}`}>
              <div style={{height: 24}}>
                <AuthorStatusIcon status={author.status} className={'card__item__status-icon me-2'}/>
              </div>
              <div className={`text-14`}>{t(ReviewStates[author.status])}</div>
            </div>
            <div className='bold-20 mt-2'>{author.stageName}</div>
            <div className='muted'>{author.lastName} {author.firstName}</div>
          </div>
          {author.rejectReason
            ?
            <div className='text-danger ms-3 text-medium-14'>{author.rejectReason}</div>
            :
            null
          }
          <div className='performer-info-links'>
            {/*<div className='performer-info-links-wrap'>*/}
            {/*  <div className='text-muted-14 mb-2' style={{lineHeight: '24px'}}>{t('LINKS_COMMUNITY')}</div>*/}
            {/*  <div className='d-flex flex-wrap flex-grow-1'>*/}
            {/*{author?.bio?.split('\n').map((link, i) => (*/}
            {/*  <a*/}
            {/*    href={link}*/}
            {/*    target={'_blank'}*/}
            {/*    key={i}*/}
            {/*    className={'text-medium-14 text-decoration-underline me-2 mb-1 text-truncate'}*/}
            {/*  >{link.replace('https://', '').replace('www.', '')}</a>*/}
            {/*))}*/}
            {/*</div>*/}
            {/*</div>*/}
          </div>
        </div>
        {(author.status !== EReviewStatus.Approve && editable) &&
          <button className='btn btn-third align-self-start performer-edit-btn' style={{minWidth: 200}} onClick={handleEdit}>
            <div className='d-flex align-items-center'>
              <div className="icon-edit" />
              <span className='ms-3'>{t('EDIT')}</span>
            </div>
          </button>
        }
      </div>
    </div>
  );
};

export default Author;