import React, {FormEvent, useCallback, useState} from 'react';
import {API, service} from '../../modules/api';
import store from '../../modules/session';
import {runInAction} from 'mobx';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';
import {observer} from 'mobx-react';
import {useTranslation} from "react-i18next";
import {Checkbox, Input, InputPassword} from "../../components/FormControls";
import AuthWrap from "./AuthWrap";
import {EFieldGroup, EService, EUserRole} from '../../modules/rest';

type TTerms = 'policy' | 'terms'

const links: Partial<{ [LINK in EService]: {[TERMS in TTerms]: string} }> = {
  [EService.Zoundo]: {
    terms: 'https://docs.google.com/document/d/1OhJdUGtBmzHQAZNSvi6yrpqR-t83YiqnbKnuvegnSas/edit?usp=sharing',
    policy: 'https://docs.google.com/document/d/1AICGqEqjM0OsWuJbE_Su84oOR2U2tAKHy2c78AigTJg/edit?usp=sharing'
  },
  [EService.Axtune]: {
    terms: 'https://docs.google.com/document/d/1wS7S0oTXy6VafPi87-L1gVDx0WFVvd7DnMOyjhnlSLs/edit?usp=sharing',
    policy: 'https://docs.google.com/document/d/1uqlm3YN5vCkrVXccoMSj66LT7n8lZ54uAboMICzHNSI/edit?usp=sharing',
  },
  [EService.Echotune]: {
    terms: 'https://docs.google.com/document/d/11z4ssK-QobwPMqdjaFs-6jurf3x7MML2fG7tvfkRh6A/edit',
    policy: 'https://docs.google.com/document/d/1QZqfxSD-yKoJnIgCZDkukPrevu7ji2WwhcuIk20o_9M/edit',
  },
  [EService.Arpeggy]: {
    terms: 'https://docs.google.com/document/d/1cPfqnGEJ0GIXe4tcoHL5Byx4oH1HjmTjHIGFHbA6UD8/edit',
    policy: 'https://docs.google.com/document/d/1-1HoNDdkdr7crpWdWcddcFFyF2xIC8Mr2jeyyjWbSD8/edit',
  }
}

const LoginRoute = observer(() => {
  const {t} = useTranslation();
  const [email, setEmail] = useState(localStorage.getItem('email') ?? '');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [agree, setAgree] = useState<boolean>(false);
  const navigate = useNavigate();

  const submit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      setLoading(true);
      API.Users.login({email, password, role: EUserRole.Performer}, [EFieldGroup.UserBalance])
        .then((res) => {
          window.localStorage.setItem('token', res.token);
          window.localStorage.setItem('email', email);
          API.setToken(res.token);
          runInAction(() => (store.user = res.user));
          store.getGenres();
          const returnPath = window.localStorage.getItem('return') ?? '/';
          window.localStorage.removeItem('return');
          navigate(returnPath, {replace: true});
        })
        .catch(toast.error)
        .finally(() => setLoading(false));
    },
    [email, password, setLoading, navigate]
  );

  return (
    <AuthWrap loading={loading} onSubmit={submit}>
      <Input
        className="mb-4"
        label={'EMAIL'}
        placeholder={t('EMAIL_PLACEHOLDER') || ''}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        autoFocus
        type="email"
        required
      />
      <InputPassword
        className="mb-4"
        onForgotPassword={() => navigate('/password')}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
      />
      {/*<Checkbox*/}
      {/*  className='mb-4'*/}
      {/*  checked={remember}*/}
      {/*  onChange={(e) => setRemember(e.target.checked)}*/}
      {/*  label={'REMEMBER_ME'}*/}
      {/*/>*/}
      <div className="text-muted mb-4 pb-3 d-flex align-items-start">
        <Checkbox
          // className={'ps-4'}
          checked={agree}
          onChange={(e) => setAgree(e.target.checked)}
          id='agree'
        />
        {/*<label className="form-check-label cursor-pointer" htmlFor="agree">*/}
        {/*  {t('TERMS_TEXT_1')}{' '}*/}
        {/*  <span*/}
        {/*    className="text-muted"*/}
        {/*  >*/}
        {/*    {t('TERMS_TEXT_2')}*/}
        {/*  </span>{' '}*/}
        {/*  {t('TERMS_TEXT_3')}{' '}*/}
        {/*  <span*/}
        {/*    className="text-muted"*/}
        {/*  >*/}
        {/*    {t('TERMS_TEXT_4')}*/}
        {/*  </span>*/}
        {/*</label>*/}
        <label className="form-check-label cursor-pointer" htmlFor="agree">
          {t('TERMS_TEXT_1')}{' '}
          <a
            href={links[service]?.terms}
            target="_blank"
            rel="noreferrer"
            className="text-muted"
          >
            {t('TERMS_TEXT_2')}
          </a>{' '}
          {t('TERMS_TEXT_3')}{' '}
          <a
            href={links[service]?.policy}
            target="_blank"
            rel="noreferrer"
            className="text-muted"
          >
            {t('TERMS_TEXT_4')}
          </a>
        </label>
      </div>

      <div className="d-flex">
        <button disabled={!agree} type="submit" className="btn btn-primary w-100">
          {t('LOGIN')}
        </button>
      </div>
    </AuthWrap>
  );
});

export default LoginRoute;
