import React, {FC, useCallback, useState} from 'react';
import './assets/music-platforms.scss';
import {IAlbumLinks} from "../../modules/rest";
import OutsideClick from "../OutsideClick";
import deezer from './assets/icons/deezer.svg';
import amazon from './assets/icons/amazon.svg';
import appleMusic from './assets/icons/apple.svg';
import soundCloud from './assets/icons/soundcloud.svg';
import spotify from './assets/icons/spotify.svg';
import tidal from './assets/icons/tidal.svg';
import yandexMusic from './assets/icons/yandex.svg';
import zvuk from './assets/icons/zvuk.svg';
import copy_svg from '../../assets/images/copy.svg';
import {ReactSVG} from "react-svg";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

const icons: any = {
  amazon,
  appleMusic,
  deezer,
  soundCloud,
  spotify,
  tidal,
  yandexMusic,
  zvuk,
}

interface Props {
  links?: IAlbumLinks;
  title?: string;
}

const MusicPlatforms: FC<Props> = ({links, title}) => {
  const {t} = useTranslation();
  const [visible, setVisible] = useState(false);

  const handleCopy = useCallback((text: string) => (e: any) => {
    e.stopPropagation();
    navigator.clipboard.writeText(text).then(() => {
      toast.success(t('COPIED'));
    });
  }, []);

  if (!links) return null;
  const {array, ...allLinks} = links;
  let linksArr = Object.entries(allLinks).filter(([key, value]) => value);
  // linksArr = [...linksArr, ...linksArr]
  const hiddenLinks = linksArr?.slice(linksArr?.length > 3 ? 2 : 3) || [];
  const visibleLinks = linksArr?.slice(0, hiddenLinks.length ? 2 : 3) || [];

  if (!linksArr?.length) return null;
  return (
    <div className={`music-platforms${visible ? ' visible' : ''}`}>
      {visibleLinks.map(link => (
        <a href={link[1] || ''} target='_blank' className="music-platforms-list-item" key={link[0]}
           onClick={(e) => e.stopPropagation()}>
          <ReactSVG src={icons[link[0]]} className='music-platforms-item-visible-icon'/>
        </a>
      ))}
      {hiddenLinks.length ?
        (
          <div className={`music-platforms-list-item union${visible ? ' visible' : ''}`}
               onClick={(e) => {
                 e.stopPropagation()
                 setVisible(prevState => !prevState)
               }}>
            <div>+{hiddenLinks.length}</div>
            <OutsideClick outsideClickEvent={() => setVisible(false)} className={`music-platforms-union-list`}>
              {title ? <h6 className='bold'>{title}</h6> : null}
              {hiddenLinks.map((link) => (
                <div className="music-platforms-hidden-item" key={link[0]}>
                  <div className='music-platforms-hidden-item-icon'>
                    <ReactSVG src={icons[link[0]]}/>
                  </div>
                  <a href={link[1] || ''} target='_blank' className='text-truncate'>
                    {link[1]}
                  </a>
                  <div className='music-platforms-hidden-item-copy' onClick={handleCopy(link[1]!)}>
                    <ReactSVG src={copy_svg}/>
                  </div>
                </div>
              ))}
            </OutsideClick>
          </div>
        )
        :
        null
      }
    </div>
  );
}

export default MusicPlatforms;