import {useNavigate, useParams} from 'react-router-dom';
import {EFieldGroup, EReviewStatus, IAlbum, IPerformer} from '../../modules/rest';
import {FC, useEffect, useState} from 'react';
import {API} from '../../modules/api';
import {toast} from 'react-toastify';
import Loader from '../../components/Loader';
import {useTranslation} from "react-i18next";
import AlbumCompose from "./AlbumCompose";

interface Props {
  author: IPerformer;
}

const EditAlbum: FC<Props> = ({author}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const params = useParams<{ albumId: string }>();

  const [album, setAlbum] = useState<IAlbum>();

  useEffect(() => {
    API.Albums.getAlbum(params.albumId!, [EFieldGroup.AlbumStatus])
      .then((album) => {
        if (album.status === EReviewStatus.Draft) {
          setAlbum(album);
        } else {
          throw new Error(t('EDIT_ONLY_DRAFT') || '');
        }
      })
      .catch((e) => {
        toast.error(e);
        navigate(-1);
      });
  }, [params.albumId, navigate, t]);

  if (!album) return <Loader/>;
  return (
    <AlbumCompose
      mode="edit"
      album={album}
      author={author}
      onUpdated={() => navigate(`../`)}
    />
  );
};

export default EditAlbum;
