import React, {FC} from 'react';
import {useTranslation} from "react-i18next";
import {IGetPerformerStatsRequest} from "../../modules/rest";

interface Props {
  group: IGetPerformerStatsRequest['group']
}


const ReportsListHead: FC<Props> = ({group}) => {
  const {t} = useTranslation();

  return (
    <div className='table-head pe-2'>
      <div style={{width: 50, minWidth: 50}}>#</div>
      <div className='flex-1'>{t(group.toUpperCase())}</div>
        <div className="reports-list-number-item">
          {t('VIEWS')}
        </div>
      {/*<div className="reports-list-number-item">*/}
      {/*  {t('COM_views')}*/}
      {/*</div>*/}
      <div className="reports-list-number-item">
        {t('INCOME')}
      </div>
      {/*<div className="reports-list-number-item">*/}
      {/*  {t('CPM')}*/}
      {/*</div>*/}
    </div>
  );
}

export default ReportsListHead;