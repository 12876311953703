import asyncModal from 'react-async-modal';
import {FC} from 'react';
import {useTranslation} from "react-i18next";
import Button from "../../components/Button";
import {IAddTrackRequest, ITrack, IUpdateTrackRequest} from "../../modules/rest";
import CloseBtn from "../../components/CloseBtn";

interface Props {
  track: Partial<IAddTrackRequest&IUpdateTrackRequest>;

  resolve(batch: ITrack|null): void;
}

const CoverRequirements: FC<Props> = ({resolve}) => {
  const {t} = useTranslation();
  return (
    <div>
      <div className='d-flex justify-content-between align-items-center mb-4'>
        <h4 className='bold'>{t('COVER_REQUIREMENTS')}</h4>
        <CloseBtn onClick={() => resolve(null)}/>
      </div>
      <div className='' dangerouslySetInnerHTML={{__html: t('COVER_REQUIREMENTS_TEXT') || ''}}/>
      <div className="modal-footer-btns">
        <Button text="CLOSE" className="ms-2" onClick={() => resolve(null)}/>
      </div>
    </div>
  );
};

const openCoverRequirementsModal = (): Promise<void> => {
  return asyncModal(CoverRequirements, {}, {styles: {modal: {width: 800}}, showCloseIcon: false});
};

export {openCoverRequirementsModal};
