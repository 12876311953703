import React, {FC} from 'react';
import {formatNumber} from "../../modules/utils";
import {useTranslation} from "react-i18next";
import {IPerformerStats} from "../../modules/rest";

interface Props {
  pager?: IPerformerStats
}

const ReportsTotal: FC<Props> = ({pager}) => {
  const {t} = useTranslation();
  if(!pager) return null;
  return (
      <div className='reports-total'>
          <div className="flex-1" style={{minWidth: 400}}>{t('TOTAL')}</div>
          <div className={`reports-list-number-item`}>{formatNumber(pager.views!) || '-'}</div>
          <div className={`reports-list-number-item bold text-white`}>${formatNumber(pager.profit, 2)}</div>
      </div>
  );
}

export default ReportsTotal;