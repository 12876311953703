import React, { FC, ReactNode } from 'react';
import { Link as BaseLink } from 'react-router-dom';
import session from '../modules/session';
import { observer } from 'mobx-react';

interface Props {
  to: string;
  exact?: boolean;
  className?: string;
  children?: ReactNode | undefined;
}

const Link: FC<Props> = observer(({ to, exact, className, children }) => {
  // const active = exact ? window.location.href === to : window.location.startsWith(to);
  return (
    // <BaseLink to={to} className={`${className ?? ''} ${active ? 'active' : ''}`}>
    <BaseLink to={to} className={`${className ?? ''}`} >
      {children}
    </BaseLink>
  );
});

export default Link;
