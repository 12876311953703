import React, {useCallback, useEffect} from 'react';
import {API} from '../../modules/api';
import {toast} from 'react-toastify';
import {useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import AuthWrap from "./AuthWrap";
import Loader from "../../components/Loader";

const PasswordResetRoute = () => {
  const {t} = useTranslation();
  const params = useParams<{ token: string }>();
  const navigate = useNavigate();

  const finish = useCallback(() => {
    API.Users.finishPasswordReset({token: params.token!}).then(() => {
      toast.success(t('PASSWORD_CHANGED'))
      navigate('/login', {replace: true});
    }).catch(e => {
      toast.error(e);
      navigate('/password', {replace: true});
    })
  }, [params.token, navigate])

  useEffect(() => {
    finish();
  }, [])

  return (
    <AuthWrap loading={false} onSubmit={() => {
    }}>
      <div className="d-flex align-items-center justify-content-center"><Loader text={t('SETTING_UP_NEW_PASSWORD')!}/>
      </div>
    </AuthWrap>

  );
};

export default PasswordResetRoute;
