import React, {FC} from 'react';
import {ReactSVG} from "react-svg";
import close from '../../../assets/images/close_small.svg';

interface Props {
  name: string;
  onClick: () => void;
}

const FilterSelectedItem: FC<Props> = ({name, onClick}) => {
  return (
    <div className='report-filters-selected-list-item' onClick={onClick}>
      <span>{name}</span>
      <ReactSVG src={close} className='react-icon cursor-pointer'/>
    </div>
  );
}

export default FilterSelectedItem;