import React, {FC, useCallback, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {EFieldGroup, EReviewStatus, IAlbum, IGetAlbumsRequest, IPagedData, IPerformer,} from '../../../modules/rest';
import Loader from '../../../components/Loader';
import {useTranslation} from 'react-i18next';
import {API} from "../../../modules/api";
import {confirmDialog} from "../../../modules/confirm";
import {runInAction} from "mobx";
import {toast} from "react-toastify";
import {observer, useLocalObservable} from "mobx-react";
import AlbumsListItem from "./AlbumsListItem";
import '../albums.scss'
import Loadable from "../../../components/Loadable";
import {signContractModal, SignSide} from "../../../modals/common/ContractModal";
import Pagination from "../../../components/Pagination";
import AlbumsListEmpty from "./AlbumsListEmpty";
import WarningLineWithBtn from "../../../components/WarningLineWithBtn";
import {openSendToReviewAlbumModal} from "../../../modals";

interface State {
  pager?: IPagedData<IAlbum>;
  draftAlbums: IAlbum[];
  request: Partial<IGetAlbumsRequest>;
  loading: boolean;
}

interface Props {
  performers?: IPerformer[];
  performer: IPerformer;
  updatePerformers: () => void;
  setAlbums: (albums: IAlbum[]) => void;
}

const AlbumsList: FC<Props> = observer(({updatePerformers, setAlbums, performer, performers}) => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const params = useParams<{ performerId: string, '*': string }>();

  const st = useLocalObservable<State>(() => ({
    loading: true,
    request: {
      page: 1,
      limit: 15,
    },
    draftAlbums: []
  }));

  useEffect(() => {
    fetch();
  }, [params?.performerId]);

  const fetch = async () => {
    if (!params?.performerId) return;
    runInAction(() => {
      st.loading = true;
    });
    try {
      const [pager, draftPager] = await Promise.all([
        API.Albums.getAlbums({
          ...st.request,
          performerId: Number(params?.performerId)
        }, [EFieldGroup.AlbumStatus, EFieldGroup.AlbumBatch, EFieldGroup.AlbumBatch, EFieldGroup.BatchContract, EFieldGroup.AlbumLinks]),
        API.Albums.getAlbums({
          page: 1,
          limit: 100,
          status: EReviewStatus.Draft,
          performerId: Number(params?.performerId),
        }, [EFieldGroup.AlbumPerformer])
      ])
      runInAction(() => {
        st.draftAlbums = draftPager?.data;
        st.pager = pager;
        st.request.page = pager.page;
        st.request.limit = pager.limit;
      });
      setAlbums(pager.data);
    } catch (e: any) {
      toast.error(e)
    } finally {
      runInAction(() => {
        st.loading = false;
      });
    }
  }

  const reviewAlbum = useCallback((album: IAlbum) => (e: any) => {
    e.stopPropagation();
    confirmDialog(t(album.isSingle ? 'SEND_SINGLE_REVIEW' : 'SEND_ALBUM_REVIEW', {title: album.title}), {confirmText: t('SUBMIT_FOR_REVIEW') || ''}).then(
      (agree) => {
        if (!agree) return;
        runInAction(() => (st.loading = true));
        API.Albums.reviewAlbum(album.id)
          .then(() => {
            toast.success(t(album.isSingle ? 'SINGLE_SENT_REVIEW' : 'ALBUM_SENT_REVIEW'));
            fetch();
            updatePerformers();
          })
          .catch(toast.error)
          .finally(() => runInAction(() => (st.loading = false)));
      }
    );
  }, [fetch, updatePerformers]);

  const editAlbum = useCallback(async (album: IAlbum) => {
    if (album.isSingle) {
      const res = await API.Albums.getAlbum(album.id, [EFieldGroup.AlbumTracks])
      navigate(`${album.id}/tracks/${res.tracks?.[0]?.id!}/edit`)
    } else {
      navigate(`${album.id}/edit/`);
    }
    // /albums/9/tracks/16/edit

  }, [navigate]);

  const draftAlbum = useCallback((album: IAlbum) => (e: any) => {
    e.stopPropagation();
    confirmDialog(
      t(album.isSingle ? 'DRAFT_SINGLE_CONFIRM' : 'DRAFT_ALBUM_CONFIRM', {title: album.title}),
      {confirmText: t('CONVERT_TO_DRAFT') || ''}
    ).then((agree) => {
      if (!agree) return;
      runInAction(() => (st.loading = true));
      API.Albums.draftAlbum(album.id)
        .then(() => {
          toast.success(t(album.isSingle ? 'SINGLE_TO_DRAFT' : 'ALBUM_TO_DRAFT'));
          fetch();
          updatePerformers()
          editAlbum(album);
        })
        .catch(toast.error)
        .finally(() => runInAction(() => (st.loading = false)));
    });
  }, [fetch]);

  const deleteAlbum = useCallback((album: IAlbum) => (e: any) => {
    e.stopPropagation();
    confirmDialog(t(album.isSingle ? 'REMOVE_SINGLE_CONFIRM' : 'REMOVE_ALBUM_CONFIRM', {title: album.title}), {danger: true}).then((agree) => {
      if (!agree) return;
      runInAction(() => (st.loading = true));
      API.Albums.deleteAlbum(album.id)
        .then(() => {
          toast.success(t(album.isSingle ? 'SINGLE_DELETED' : 'ALBUM_DELETED'));
          fetch();
        })
        .catch(toast.error)
        .finally(() => runInAction(() => (st.loading = false)));
    });
  }, [fetch]);

  const signContract = useCallback((album: IAlbum) => (e: any) => {
    e.stopPropagation();
    signContractModal(album.batch!, SignSide.One).then(fetch)
  }, [fetch]);

  const handleOpenModerateModal = useCallback(() => {
    openSendToReviewAlbumModal({data: st.draftAlbums, performerId: params?.performerId}).then(fetch);
  }, [st.draftAlbums, params?.performerId, fetch]);

  if (!st.pager?.data) return <Loader/>;
  if (!st.pager?.data?.length) return <AlbumsListEmpty/>
  return (
    <>
      <WarningLineWithBtn
        className='mb-2 pb-1'
        text={'UNVERIFIED_ALBUMS'}
        btnText={'SEND_TO_REVIEW'}
        onClick={handleOpenModerateModal}
        visible={!!st.draftAlbums?.length}
      />

      <Loadable loading={st.loading}>
        <div className='albums-list'>
          {st.pager.data?.map((album) => (
            <AlbumsListItem
              key={album.id}
              album={album}
              performer={performer}
              deleteAlbum={deleteAlbum(album)}
              draftAlbum={draftAlbum(album)}
              reviewAlbum={reviewAlbum(album)}
              signContract={signContract(album)}
              editAlbum={() => editAlbum(album)}
            />
          ))}
        </div>
        <Pagination
          className='mb-4'
          textType='ALBUMS'
          pager={st.pager}
          onPageChange={(page) => runInAction(() => {
            st.request.page = page;
            fetch();
          })}
          onLimitChange={(limit) => runInAction(() => {
            st.request.page = 1;
            st.request.limit = limit;
            fetch();
          })}
        />
      </Loadable>
    </>
  );
});

export default AlbumsList;
